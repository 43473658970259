import React from "react";
import registerimg from "../../Assets/Image/auth-bg.png";
import { AiOutlineCheckCircle } from "react-icons/ai";
import logo from "../../Assets/logo/pastewallet2.png";
import darklogo from "../../Assets/logo/pastewallet1.png";

export default function ResetMessage({ theme }) {
  return (
    <>
      <div className="container-new pa-1">
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="create-bg">
              <div className="d-f j-c-s-b m-t-1">
                <div className="image-dog1">
                  {theme != "dark-theme" ? (
                    <a href="/dashboard">
                      <img src={logo} alt="" className="logoimg" />
                    </a>
                  ) : (
                    <a href="/dashboard">
                      <img src={darklogo} alt="" className="logoimg" />
                    </a>
                  )}
                </div>
              </div>

              <div className="t-a-c m-t-5">
                <div>
                  <AiOutlineCheckCircle className="f-s-3 c-g" />
                </div>
                <h3 className="m-t-5 c-g f-w-800">RESET LINK SENT</h3>
                <p>
                  We have sent a password reset link to your email. click the
                  link login back into your account
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 login-hide">
            <div className="registerimg-div p-t-5">
              <img src={registerimg} className="w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
