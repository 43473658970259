import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import waveimg from "../../Assets/Svg/wave6.svg";
import {
  AiOutlineEyeInvisible,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
} from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import axios from "axios";
import Tokens_List from "./Listoftokens";
import Sidenavbar from "./Sidenavbar";
import metamaskimg from "../../Assets/Image/metamask.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import ProgressBar from "./ProgressBar";

//................Function to get the greeting based on the provided hour...............//
function getGreetingByHour(hour) {
  let greeting = "";

  if (hour >= 0 && hour < 12) {
    greeting = "Good Morning";
  } else if (hour >= 12 && hour < 17) {
    greeting = "Good Afternoon";
  } else if (hour >= 17 && hour < 20) {
    greeting = "Good Evening";
  } else {
    greeting = "Good Night";
  }
  return `${greeting}`;
}

// Get the user's detected timezone
const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Get the current hour in the user's detected timezone
const currentTime = new Date(
  new Date().toLocaleString("en-US", { timeZone: userTimezone })
);
const userHour = currentTime.getHours();

const timeGreeting = getGreetingByHour(userHour);
const FetchingEmail = localStorage.getItem("email");

//....................MODAL FOR METAMASK................................//

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Dashboard({ DB_API, theme, Metamask, account }) {
  const [depositToken, setDepositToken] = useState();
  const [tradingToken, setTradingToken] = useState();
  const [withdrawBalance, setWithdrawBalance] = useState();
  const [usdPrice, setUSDPrice] = useState();
  const [tokenTradingAmounts, setTokenTradingAmounts] = useState({});
  const [isActive, setIsActive] = useState(true);
  const [isKYCDONE, setIsKYCDONE] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [priceLoading, setPriceLoading] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const FetchingUsername = localStorage.getItem("username");

  const sliceAdd = (address) => {
    const first = address.slice(0, 4);
    const second = address.slice(38);
    return first + "..." + second;
  };

  const Fetching_User_Deposit_Token = async () => {
    try {
      const { data } = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: FetchingEmail,
      });

      setDepositToken(data.findingUserData.funding_balance);
      setTradingToken(data.findingUserData.trading_balance);
      setWithdrawBalance(data.findingUserData.total_withdraw_balance);
    } catch (error) {
      toast.error(error);
    }
  };

  const Fetching_USD_Price = async () => {
    try {
      const { data } = await axios.get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=usd&per_page=100&page=1&sparkline=false&locale=en"
      );
      setUSDPrice(data[0].current_price);
    } catch (error) {
      toast.error("We are fetching Latest Price please wait for a while");
    }
  };

  const getTokenAmount = async () => {
    try {
      const { data } = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: FetchingEmail,
      });
      //............FOR TRADING..........//

      const tradingarray = data.findingUserData.trading_token;
      const tokenAmountTradingObject = {};

      for (let index = 0; index < tradingarray.length; index++) {
        const element = tradingarray[index].tokenamount;
        const tokenName = tradingarray[index].tokenname;

        // If token name already exists, add the amount, otherwise initialize it
        if (tokenAmountTradingObject[tokenName]) {
          tokenAmountTradingObject[tokenName] += element;
        } else {
          tokenAmountTradingObject[tokenName] = element;
        }
      }
      setTokenTradingAmounts(tokenAmountTradingObject);
    } catch (error) {
      toast.error(error);
    }
  };

  const toggleVisibility = () => {
    setIsActive(!isActive);
  };

  const Fetching_Token_Details = async () => {
    try {
      const { data } = await axios.get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en"
      );
      const USDC = Tokens_List.find(
        (token) => token.name.toLowerCase() === "usdc"
      );
      if (USDC) {
        USDC.marketPrice = data[6].current_price;
        USDC.priceChange24h = data[6].price_change_24h;
        USDC.priceChangePercent24h = data[6].price_change_percentage_24h;
      }
      const WBTC = Tokens_List.find(
        (token) => token.name.toLowerCase() === "wbtc"
      );
      if (WBTC) {
        WBTC.marketPrice = data[16].current_price;
        WBTC.priceChange24h = data[16].price_change_24h;
        WBTC.priceChangePercent24h = data[16].price_change_percentage_24h;
      }

      const SOL = Tokens_List.find(
        (token) => token.name.toLowerCase() === "solana"
      );
      if (SOL) {
        SOL.marketPrice = data[4].current_price;
        SOL.priceChange24h = data[4].price_change_24h;
        SOL.priceChangePercent24h = data[4].price_change_percentage_24h;
      }

      const PolygonMatic = Tokens_List.find(
        (token) => token.name.toLowerCase() === "polygon"
      );
      if (PolygonMatic) {
        PolygonMatic.marketPrice = data[14].current_price;
        PolygonMatic.priceChange24h = data[14].price_change_24h;
        PolygonMatic.priceChangePercent24h =
          data[14].price_change_percentage_24h;
      }

      const USDT = Tokens_List.find(
        (token) => token.name.toLowerCase() === "tether"
      );
      if (USDT) {
        USDT.marketPrice = data[2].current_price;
        USDT.priceChange24h = data[2].price_change_24h;
        USDT.priceChangePercent24h = data[2].price_change_percentage_24h;
      }
      const Dai = Tokens_List.find(
        (token) => token.name.toLowerCase() === "dai"
      );
      if (Dai) {
        Dai.marketPrice = data[20].current_price;
        Dai.priceChange24h = data[20].price_change_24h;
        Dai.priceChangePercent24h = data[20].price_change_percentage_24h;
      }
      return Tokens_List;
    } catch (error) {
      toast.error(error);
    }
  };

  const Price_WMATIC = async () => {
    try {
      const { data } = await axios.get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=wmatic&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en"
      );

      const wmaticToken = Tokens_List.find(
        (token) => token.name.toLowerCase() === "wmatic"
      );
      if (wmaticToken) {
        wmaticToken.marketPrice = data[0].current_price;
        wmaticToken.priceChange24h = data[0].price_change_24h;
        wmaticToken.priceChangePercent24h = data[0].price_change_percentage_24h;
      }
      return wmaticToken;
    } catch (error) {
      toast.error(error);
    }
  };

  const Price_WETH = async () => {
    try {
      const { data } = await axios.get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=weth&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en"
      );

      const wethToken = Tokens_List.find(
        (token) => token.name.toLowerCase() === "weth"
      );
      if (wethToken) {
        wethToken.marketPrice = data[0].current_price;
        wethToken.priceChange24h = data[0].price_change_24h;
        wethToken.priceChangePercent24h = data[0].price_change_percentage_24h;
      }

      return wethToken;
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    axios
      .post(`${DB_API}/api/fetchProfile`, {
        email: FetchingEmail,
      })
      .then((res) => {
        setIsKYCDONE(res.data.findingUserData.isKYCDone);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tokenDetails, wmaticDetails, wethDetails] = await Promise.all([
          Fetching_User_Deposit_Token(),
          Fetching_USD_Price(),
          getTokenAmount(),
          Fetching_Token_Details(),
          Price_WMATIC(),
          Price_WETH(),
        ]);
        setPriceLoading(false); // Set price loading to false after fetching prices
      } catch (error) {
        console.error("Error fetching data:", error);
        setPriceLoading(false); // Set price loading to false if an error occurs
      }
    };

    fetchData();
  }, []);

  const TotalWithdrawAssests = withdrawBalance / usdPrice;

  return (
    <>
      <div>
        <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar theme={theme} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
            <div className="dashboard-content">
              <div className="d-f j-c-s-b m-b-2">
                <div>
                  <h1>Dashboard</h1>
                  <p>
                    {timeGreeting} {FetchingUsername}
                  </p>
                </div>
                {!account ? (
                  <div className="d-f">
                    <Button onClick={handleOpen}>Connect Wallet</Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Please connect your wallet from below
                        </Typography>
                        <img
                          src={metamaskimg}
                          alt=""
                          className="metamaask-img"
                          onClick={Metamask}
                        />
                      </Box>
                    </Modal>
                  </div>
                ) : (
                  account
                )}
              </div>
              <div className="row wallet-box pa-0_5">
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                  <div className="wallet-balance">
                    <p>Total Assets Balance</p>
                    <div className="d-f j-c-s-b ">
                      {!isActive ? (
                        <p className="f-s-2 m-t-1_5">
                          $
                          {TotalWithdrawAssests
                            ? TotalWithdrawAssests.toFixed(5)
                            : 0.0}
                          USD
                        </p>
                      ) : (
                        <p className="m-t-1_5 font">$XXXXxxxx</p>
                      )}

                      <div>
                        {!isActive ? (
                          <FaEye
                            onClick={toggleVisibility}
                            className="showideicon"
                            size={32}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            onClick={toggleVisibility}
                            className="showideicon"
                            size={32}
                          />
                        )}
                      </div>
                    </div>
                    <img src={waveimg} className="waveimg" alt="" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                  <div className="d-f fund">
                    <div>
                      <div className="square"></div>
                    </div>
                    <div className="d-g m-l-1">
                      <p>Funding Balance</p>
                      <p>$ {depositToken ? depositToken.toFixed(2) : 0.0}</p>
                    </div>
                  </div>
                  <div className="d-f m-t-1">
                    <div>
                      <div className="square-blue"></div>
                    </div>
                    <div className="d-g m-l-1">
                      <p>Trading Balance</p>
                      <p>$ {tradingToken ? tradingToken.toFixed(2) : 0.0}</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                  {!depositToken && !tradingToken ? (
                    <PieChart
                      className="pie_chart"
                      data={[
                        {
                          title: "One",
                          value: 50,
                          color: "#E4B90C",
                        },

                        {
                          title: "two",
                          value: 50,
                          color: "#71299A",
                        },
                      ]}
                    />
                  ) : (
                    <PieChart
                      className="pie_chart"
                      data={[
                        {
                          title: "One",
                          value: depositToken,
                          color: "#E4B90C",
                        },

                        {
                          title: "two",
                          value: tradingToken,
                          color: "#71299A",
                        },
                      ]}
                    />
                  )}
                </div>
                <div className="row m-t-4">
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 ">
                    <div>
                      <NavLink to="/deposite">
                        {" "}
                        <button className="button-blue">
                          <AiOutlineArrowDown className="b-c-t" />
                          Deposite
                        </button>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <NavLink to="/transfer">
                      <button className="button-grey trans">
                        <BsBoxArrowUpRight className="b-c-t" />
                        Transfer
                      </button>
                    </NavLink>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <NavLink to="/withdraw">
                      {" "}
                      <button className="button-blue trans">
                        <AiOutlineArrowUp className="b-c-t" />
                        Withdraw
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div>
                <div className="m-t-2 f-w-800 f-s-1">
                  <p>Pending Actions</p>
                </div>
                <div className="row">
                  {isKYCDONE ? (
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="pending-action transition-c">
                        <p>Phone Number</p>
                        <button className="set-btn">Verified</button>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="pending-action transition-c">
                        <p>Verify Phone Number</p>
                        <button className="set-btn">
                          <Link to="/accountsetting">Set now</Link>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div className="m-t-2 m-b-1 f-s-1 f-w-800">My Assets</div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Image</TableCell>
                          <TableCell align="center">Token</TableCell>
                          <TableCell align="center">Market Price</TableCell>
                          <TableCell align="center">Price Change 24h</TableCell>
                          <TableCell align="center">
                            Price Change Percentage 24h
                          </TableCell>
                          <TableCell align="center">Trading Balance</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {priceLoading && <ProgressBar />}
                        {Tokens_List.map((token) => (
                          <TableRow
                            key={token.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              <Avatar
                                alt="Remy Sharp"
                                src={token.image}
                                style={{ display: "block", margin: "auto" }}
                              />
                            </TableCell>

                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              {token.name}({token.symbol})
                            </TableCell>

                            <TableCell align="center">
                              ${token.marketPrice}
                            </TableCell>
                            <TableCell align="center">
                              {token.priceChange24h > 0 ? (
                                <span style={{ color: "green" }}>
                                  <BiSolidUpArrow style={{ color: "green" }} />
                                  {token.priceChange24h.toFixed(2) === 0.0
                                    ? token.priceChange24h
                                    : token.priceChange24h.toFixed(4)}
                                  %
                                </span>
                              ) : token.priceChange24h < 0 ? (
                                <span style={{ color: "red" }}>
                                  <BiSolidDownArrow style={{ color: "red" }} />{" "}
                                  {token.priceChange24h.toFixed(2) === 0.0
                                    ? token.priceChange24h
                                    : token.priceChange24h.toFixed(4)}
                                  %
                                </span>
                              ) : (
                                <span>{token.priceChange24h.toFixed(2)}%</span>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {token.priceChangePercent24h > 0 ? (
                                <span style={{ color: "green" }}>
                                  <BiSolidUpArrow style={{ color: "green" }} />
                                  {token.priceChangePercent24h.toFixed(2) ===
                                  0.0
                                    ? token.priceChangePercent24h
                                    : token.priceChangePercent24h.toFixed(4)}
                                  %
                                </span>
                              ) : token.priceChangePercent24h < 0 ? (
                                <span style={{ color: "red" }}>
                                  <BiSolidDownArrow style={{ color: "red" }} />
                                  {token.priceChangePercent24h.toFixed(2) ===
                                  0.0
                                    ? token.priceChangePercent24h
                                    : token.priceChangePercent24h.toFixed(4)}
                                  %
                                </span>
                              ) : (
                                <span>
                                  {token.priceChangePercent24h.toFixed(2)}%
                                </span>
                              )}
                            </TableCell>

                            <TableCell align="center">
                              {tokenTradingAmounts[token.name]
                                ? `${tokenTradingAmounts[token.name].toFixed(
                                    2
                                  )}$`
                                : "$0"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
}
