import { Grid } from "@mui/material";
import React from "react";
import Sidenavbar from "./Sidenavbar";
import avatar from "../../Assets/Image/zendwallet-avatar.png";
import { FaExchangeAlt } from "react-icons/fa";
import { IoLogoUsd } from "react-icons/io5";

import { NavLink } from "react-router-dom";

export default function Transaction({ theme, DB_API }) {
  return (
    <>
      <div>
        <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar theme={theme} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
            <div className="dashboard-content">
              <div className="d-f j-c-s-b m-b-2">
                <div>
                  <h3>Transactions</h3>
                  <p>Select a transaction history you'll like to view</p>
                </div>
                <div>
                  {" "}
                  <div className="t-a-c">
                    <img src={avatar} className="w-25 b-r-25 " />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="transaction">
                    <div>
                      <p className="f-s-1_5 f-w-800">Token Transactions</p>
                      <p>View all your Token transactions</p>
                      <NavLink to="/tansactiondetail">
                        {" "}
                        <button className="see-btn m-t-2">See Details</button>
                      </NavLink>
                    </div>
                    <div className="exchange">
                      <IoLogoUsd className="f-s-1_5 coin" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  {" "}
                  <div className="transaction-bl">
                    <div>
                      <p className="f-s-1_5 f-w-800">Swap History</p>
                      <p>View all your Swap transactions</p>
                      <NavLink to="/swap-history">
                        {" "}
                        <button className="see-btn m-t-2">See Details</button>
                      </NavLink>
                    </div>
                    <div className="exchange">
                      {" "}
                      <FaExchangeAlt className="f-s-1_5 coin" />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row m-t-2">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="transaction">
                    <div>
                      <p className="f-s-1_5 f-w-800">
                        Paste Wallet Prepaid History
                      </p>
                      <p>View all your Swap transactions</p>
                      <button className="see-btn m-t-2">See Details</button>
                    </div>
                    <div className="exchange">
                      {" "}
                      <RiFileList3Fill className="f-s-1_5 coin" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  {" "}
                  <div className="transaction-ye">
                    <div>
                      <p className="f-s-1_5 f-w-800">Paste Wallet USD</p>
                      <p>View all your USD transactions</p>
                      <NavLink to="/tokenmatic">
                        {" "}
                        <button className="see-btn m-t-2">See Details</button>
                      </NavLink>
                    </div>
                    <div className="exchange">
                      <BsCurrencyDollar className="f-s-1_5 coin" />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
