import { Grid } from "@mui/material";
import React from "react";
import Sidenavbar from "./Sidenavbar";
import avatar from "../../Assets/Image/zendwallet-avatar-removebg-preview.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import tetherimg from "../../Assets/Image/tether.png";
import ethimg from "../../Assets/Image/ethereum.png";
import usdcimg from "../../Assets/Image/usdc.png";
import tronimg from "../../Assets/Image/tron.png";
import solanaimg from "../../Assets/Image/solana.png";
import bitcoinimg from "../../Assets/Image/bitcoin.png";
import rippleimg from "../../Assets/Image/ripple.png";
import alogimg from "../../Assets/Image/algorand.png";
import bitcoinsimg from "../../Assets/Image/bitcoin.png";
import polyimg from "../../Assets/Image/polygon.png";
import avalanchimg from "../../Assets/Image/avalanche.png";
import stellarimg from "../../Assets/Image/stellar.png";
import liteimg from "../../Assets/Image/litecoin.png";
import dogimg from "../../Assets/Image/dogecoin-doge-logo.png";
import okximg from "../../Assets/Image/okb.png";
import { NavLink } from "react-router-dom";

export default function TransferToken() {
  return (
    <>
      <div>
        <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
            {" "}
            <div>
              <div>
                <div className="d-f j-c-e m-b-2">
                  <div className="d-f">
                    {" "}
                    {/*   <Form className="m-t-1">
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label=""
                      />
                    </Form> */}
                    <div className="t-a-c">
                      <img src={avatar} className="w-25 b-r-25 " />
                    </div>
                  </div>
                </div>
                {/* Box-deposite----------- */}
                <div className="">
                  <div className="deposite-child">
                    <div>
                      <div className="c-p">
                        <AiOutlineCloseCircle className="m-r-0_5 b-c-t" />
                        close
                      </div>
                      <div className="t-a-c m-t-3">
                        <h4 className="f-w-600">Transfer Assets</h4>
                        <p>Select assets you want to transfer</p>
                        <input
                          className="search-de m-t-1 m-b-1"
                          placeholder="Search Tokens"
                        />
                      </div>
                      {/*  <div className="d-f j-c-s-b">
                        <p className="f-w-600">Token </p>
                        <p className="f-w-600">Balance</p>
                      </div> */}

                      <div className="">
                        {/*   <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={ethimg} className="w-15 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">Ethereum(ETH)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div>
                          <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={usdcimg} className="w-15 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">USDC(USDC)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div>
                          <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={tronimg} className="w-15 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">TRON(TRX)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div> */}
                        {/*  <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={solanaimg} className="w-10 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">Solana(SOL)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div> */}
                        <NavLink to="">
                          {" "}
                          <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={bitcoinimg} className="w-20 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">Bitcoins(BTC)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div>
                        </NavLink>
                        {/*   <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={rippleimg} className="w-15 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">Ripple(XRP)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div>
                          <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={alogimg} className="w-20 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">Algorand(ALGO)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div>
                          <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={bitcoinsimg} className="w-15 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">Bitcoin Cash(BCH)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div>
                          <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={polyimg} className="w-10 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">Polygon(MATIC)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div>
                          <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={avalanchimg} className="w-15 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">Avalanche(AVAX)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div>
                          <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={stellarimg} className="w-20 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">Stellar(XLM)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div>
                          <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={liteimg} className="w-17 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">LiteCoin(LTC)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div>
                          <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={dogimg} className="w-10 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">DogeCoin(DOGE)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div>
                          <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <img src={okximg} className="w-20 " />{" "}
                                <p className="m-t-0_5 m-l-0_5">OKX(OKB)</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0</p>
                                <p className="c-gr f-s-0_8">$0</p>
                              </div>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
