import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";
import { CgArrowsExchange, CgProfile } from "react-icons/cg";
import { BiUpArrowAlt, BiLogOutCircle } from "react-icons/bi";
import { BsCashCoin, BsKey } from "react-icons/bs";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { IoLogoUsd } from "react-icons/io5";
import { MdDashboard } from "react-icons/md";
import { RiExchangeDollarLine } from "react-icons/ri";
import { GiCardExchange } from "react-icons/gi";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineLogout } from "react-icons/ai";
import logo from "../../Assets/logo/pastewallet2.png";
import darklogo from "../../Assets/logo/pastewallet1.png";

export default function Sidenavbar({ theme }) {
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const navigate = useNavigate();

  const Logout = async () => {
    try {
      await localStorage.removeItem("email");
      await localStorage.removeItem("username");
      await localStorage.removeItem("token");
      await localStorage.removeItem("number");
      if (toast.success("Logged out")) {
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div className="sidebar-bg">
        <div className="sidebar m-q-b-d-n">
          <div className="sidebar__inner">
            <div className="t-a-c ">
              <div className="image-dog1">
                {theme != "dark-theme" ? (
                  <a href="/dashboard">
                    <img src={logo} alt="" className="logoimg" />
                  </a>
                ) : (
                  <a href="/dashboard">
                    <img src={darklogo} alt="" className="logoimg" />
                  </a>
                )}
              </div>
            </div>
            <hr />
            <ul className="l-s-t-n t-d-n ">
              <span>Menu</span>
              <NavLink className="nav_link" to="/dashboard">
                <li className="p-y-0_5 p-x-1_5 c-p m-t-1 sidebar__tabs ">
                  <div className="d-f a-i-c">
                    <div className="">
                      <MdDashboard className="f-s-1_5 b-c-t" />
                      &nbsp;&nbsp;
                    </div>

                    <div className="">
                      <span className="font-f  f-w-600 ">Dashboard</span>
                    </div>
                  </div>
                </li>
              </NavLink>
              <NavLink className="nav_link" to="/assets">
                <li className="p-y-0_5 p-x-1_5 c-p m-t-1 sidebar__tabs ">
                  <div className="d-f a-i-c">
                    <div className="">
                      <BsCashCoin className="f-s-1_5 b-c-t" />
                      &nbsp;&nbsp;
                    </div>
                    <div className="">
                      <span className="font-f  f-w-600 ">Assets</span>
                    </div>
                  </div>
                </li>
              </NavLink>
              <NavLink className="nav_link" to="/transaction">
                <li className="p-y-0_5 p-x-1_5 c-p m-t-1 sidebar__tabs ">
                  <div className="d-f a-i-c">
                    <div className="">
                      <IoLogoUsd className="f-s-1_5 b-c-t" />
                      &nbsp;&nbsp;
                    </div>
                    <div className="">
                      <span className="font-f  f-w-600 ">Transactions</span>
                    </div>
                  </div>
                </li>
              </NavLink>
              <NavLink className="nav_link" to="/depositetoken">
                <li className="p-y-0_5 p-x-1_5 c-p m-t-1 sidebar__tabs ">
                  <div className="d-f a-i-c">
                    <div className="">
                      <BiUpArrowAlt className="f-s-1_5 b-c-t" />
                      &nbsp;&nbsp;
                    </div>
                    <div className="">
                      <span className="font-f  f-w-600 ">Deposite</span>
                    </div>
                  </div>
                </li>
              </NavLink>
              <NavLink className="nav_link" to="/swap">
                <li className="p-y-0_5 p-x-1_5 c-p m-t-1 sidebar__tabs ">
                  <div className="d-f a-i-c">
                    <div className="">
                      <CgArrowsExchange className="f-s-1_5 b-c-t" />
                      &nbsp;&nbsp;
                    </div>
                    <div className="">
                      <span className="font-f  f-w-600 ">Swap</span>
                    </div>
                  </div>
                </li>
              </NavLink>
              <NavLink className="nav_link" to="/withdrawassets">
                <li className="p-y-0_5 p-x-1_5 c-p m-t-1 sidebar__tabs ">
                  <div className="d-f a-i-c">
                    <div className="">
                      <AiOutlineArrowDown className="f-s-1_5 b-c-t" />
                      &nbsp;&nbsp;
                    </div>
                    <div className="">
                      <span className="font-f  f-w-600 " onClick={handleShow}>
                        Withdraw
                      </span>
                    </div>
                  </div>
                </li>
              </NavLink>
              <li>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    {/* <Modal.Title>Modal title</Modal.Title> */}
                  </Modal.Header>
                  <div className="t-a-c">
                    <BsKey className="f-s-2 key m-t-1" />
                  </div>
                  <Modal.Body className="t-a-c ">
                    <p className="f-w-600 f-s-1_5">Transaction Pin</p>
                    <p>Transaction pin is required to continue</p>
                  </Modal.Body>
                  <Modal.Footer className="t-a-c j-c-c">
                    <div className="t-a-c">
                      <button
                        className="button-blue w-100"
                        onClick={handleShow}
                      >
                        Create Transaction Pin
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </li>
              <li>
                <Modal onHide={handleClose} backdrop="static" keyboard={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                  </Modal.Header>
                  <div className="t-a-c">
                    <BsKey className="f-s-2 key m-t-1" />
                  </div>
                  <Modal.Body className="t-a-c ">
                    <p className="f-w-600 f-s-1_5">Create Transaction Pin</p>
                    <p>
                      Enter the details below to create your transaction pin
                    </p>
                  </Modal.Body>
                  <Modal.Body className="t-a-l ">
                    <p className="f-w-600">Enter Pin</p>
                    <div className="d-f">
                      <p className="password m-r-0_5">*</p>
                      <p className="password m-r-0_5">*</p>
                      <p className="password m-r-0_5">*</p>
                      <p className="password m-r-0_5">*</p>
                    </div>
                  </Modal.Body>
                  <Modal.Body className="t-a-l ">
                    <p className="f-w-600">Confirm New Pin</p>
                    <div className="d-f">
                      <p className="password m-r-0_5">*</p>
                      <p className="password m-r-0_5">*</p>
                      <p className="password m-r-0_5">*</p>
                      <p className="password m-r-0_5">*</p>
                    </div>
                    <div>
                      <input className="search-de m-t-2" />
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="t-a-c j-c-c">
                    <div className="t-a-c">
                      <button className="button-blue w-100">Change Pin</button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </li>
              <NavLink className="nav_link" to="/transfer">
                <li className="p-y-0_5 p-x-1_5 c-p m-t-1 sidebar__tabs ">
                  <div className="d-f a-i-c">
                    <div className="">
                      <BsBoxArrowUpRight className="f-s-1_5 b-c-t" />
                      &nbsp;&nbsp;
                    </div>
                    <div className="">
                      <span className="font-f  f-w-600 ">Transfer</span>
                    </div>
                  </div>
                </li>
              </NavLink>
              <NavLink className="nav_link" to="/card">
                <li className="p-y-0_5 p-x-1_5 c-p m-t-1 sidebar__tabs ">
                  <div className="d-f a-i-c">
                    <div className="">
                      <GiCardExchange className="f-s-1_5 b-c-t" />
                      &nbsp;&nbsp;
                    </div>
                    <div className="">
                      <span className="font-f  f-w-600 ">Paste Card</span>
                    </div>
                  </div>
                </li>
              </NavLink>
              <NavLink className="nav_link" to="/cardcoin">
                <li className="p-y-0_5 p-x-1_5 c-p m-t-1 sidebar__tabs ">
                  <div className="d-f a-i-c">
                    <div className="">
                      <RiExchangeDollarLine className="f-s-1_5 b-c-t" />
                      &nbsp;&nbsp;
                    </div>
                    <div className="">
                      <span className="font-f  f-w-600 ">Paste Coin</span>
                    </div>
                  </div>
                </li>
              </NavLink>
            </ul>
            <hr />
            <ul className="l-s-t-n t-d-n">
              <span>Settings</span>
              <NavLink className="nav_link" to="/accountsetting">
                {" "}
                <li className="p-y-0_5 p-x-1_5 c-p m-t-1 sidebar__tabs ">
                  <div className="d-f a-i-c">
                    <div className="">
                      <CgProfile className="f-s-1_5 b-c-t " />
                    </div>

                    <div className="">
                      <span className="font-f f-w-600 ">Account setting</span>
                    </div>
                  </div>
                </li>
              </NavLink>
              <li className="p-y-0_5 p-x-1_5 c-p m-t-1 sidebar__tabs ">
                <div className="d-f a-i-c">
                  <div className="">
                    <BiLogOutCircle className="f-s-1_5 b-c-t" />
                    &nbsp;&nbsp;
                  </div>

                  <div className="">
                    <span className="font-f  f-w-600 " onClick={Logout}>
                      Logout
                    </span>
                  </div>
                  <Modal
                    show3={show3}
                    onHide={handleClose3}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Modal title</Modal.Title>
                    </Modal.Header>
                    <div className="t-a-c">
                      <AiOutlineLogout className="f-s-2 key m-t-1" />
                    </div>
                    <Modal.Body className="t-a-c ">
                      <p className="f-w-600 f-s-1_5">Confirm Logout</p>
                      <p>Are you sure about this?</p>
                    </Modal.Body>
                    <Modal.Footer className="t-a-c j-c-c">
                      <div className="t-a-c">
                        <button className="button-blue w-100" onClick={Logout}>
                          Logout
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>
              </li>
              {/* <NavLink className="nav_link" to="/">
    
              </NavLink> */}
            </ul>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
}
