export const Testnet_Contract_Address =
  "0xe9e00E7031A53861b258C9cD1Fdc36f4a7A548A4";

export const Testnet_ABI = [
  {
    inputs: [
      { internalType: "address", name: "_admin", type: "address" },
      { internalType: "uint256", name: "_withdrawalFee", type: "uint256" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newAdmin",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "oldAdmin",
        type: "address",
      },
    ],
    name: "AdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "walletOwner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "withdrawalFee",
        type: "uint256",
      },
    ],
    name: "AdminFeeTransfered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "walletOwner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "decimals",
        type: "uint256",
      },
    ],
    name: "DepositInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "ownerAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "tokenIn",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "tokenOut",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amountIn",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amountout",
        type: "uint256",
      },
    ],
    name: "SwappedTokenForToken",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          { internalType: "uint256", name: "index", type: "uint256" },
          { internalType: "address", name: "tokenAddress", type: "address" },
          { internalType: "address", name: "walletOwner", type: "address" },
          { internalType: "uint256", name: "balance", type: "uint256" },
          { internalType: "uint256", name: "decimals", type: "uint256" },
        ],
        indexed: false,
        internalType: "struct PasteWalletContract.WalletEntry",
        name: "newWallet",
        type: "tuple",
      },
    ],
    name: "WalletRegistered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "walletOwner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "WithdrawInitialized",
    type: "event",
  },
  {
    inputs: [{ internalType: "address", name: "_newAdmin", type: "address" }],
    name: "changeAdminByAdmin",
    outputs: [
      { internalType: "address", name: "newAdmin", type: "address" },
      { internalType: "address", name: "oldAdmin", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_tokenAddress", type: "address" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "depositToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getCurrentAdmin",
    outputs: [
      { internalType: "address", name: "currentAdmin", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getMyRegisteredWallets",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "index", type: "uint256" },
          { internalType: "address", name: "tokenAddress", type: "address" },
          { internalType: "address", name: "walletOwner", type: "address" },
          { internalType: "uint256", name: "balance", type: "uint256" },
          { internalType: "uint256", name: "decimals", type: "uint256" },
        ],
        internalType: "struct PasteWalletContract.WalletEntry[]",
        name: "myWallets",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_tokenAddress", type: "address" },
      { internalType: "address", name: "_walletOwner", type: "address" },
    ],
    name: "getWalletBalance",
    outputs: [
      { internalType: "uint256", name: "walletBalance", type: "uint256" },
      { internalType: "uint256", name: "walletDecimals", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_tokenAddress", type: "address" },
      { internalType: "address", name: "_walletOwner", type: "address" },
    ],
    name: "getWalletByOwner",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "index", type: "uint256" },
          { internalType: "address", name: "tokenAddress", type: "address" },
          { internalType: "address", name: "walletOwner", type: "address" },
          { internalType: "uint256", name: "balance", type: "uint256" },
          { internalType: "uint256", name: "decimals", type: "uint256" },
        ],
        internalType: "struct PasteWalletContract.WalletEntry",
        name: "wallet",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getWithdrawalFee",
    outputs: [
      { internalType: "uint256", name: "newWithdrawalFee", type: "uint256" },
      {
        internalType: "uint256",
        name: "_withdrawalFeeDecimals",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_tokenIn", type: "address" },
      { internalType: "address", name: "_tokenOut", type: "address" },
      { internalType: "uint256", name: "_amountIn", type: "uint256" },
      { internalType: "address", name: "_router", type: "address" },
    ],
    name: "swapTokenForToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_newFee", type: "uint256" },
      {
        internalType: "uint256",
        name: "_withdrawalFeeDecimals",
        type: "uint256",
      },
    ],
    name: "updateWithdrawalFee",
    outputs: [
      { internalType: "uint256", name: "newWithdrawalFee", type: "uint256" },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_tokenAddress", type: "address" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "withdrawToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
