import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./Loader";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ DB_API }) {
  const [value, setValue] = useState(0);
  const [phone, setPhone] = useState("");
  const [verifyOTP, setVerifyOTP] = useState();
  const [isKYCDONE, setIsKYCDONE] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const FetchingEmail = localStorage.getItem("email");

  const Sendotptonumber = async () => {
    try {
      setLoading(true);
      const formattedPhone = `+${phone.replace(/\D/g, "")}`;
      const { data } = await axios.post(`${DB_API}/api/sendotptonumber`, {
        email: FetchingEmail,
        phonenumber: formattedPhone,
      });
      localStorage.setItem("number", data.phonenumber);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const phoneno = localStorage.getItem("number");

  const Verifyotpandcompletekyc = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${DB_API}/api/verifyotpandcompletekyc`,
        {
          phonenumber: phoneno,
          otp: verifyOTP,
        }
      );
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const kycInterval = setInterval(async () => {
      const { data } = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: FetchingEmail,
      });
      setIsKYCDONE(data.findingUserData.isKYCDone);
    }, 1000);
    return () => {
      clearInterval(kycInterval);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {loading && <Loader />}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Level One" {...a11yProps(0)} />
          {/*   <Tab label="Level Two" {...a11yProps(1)} disabled /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className="m-b-2">
          <p className="f-s-1_5 f-w-800 ">Phone Number</p>
          <p>Provide your phone number for Verification</p>
        </div>
        <div>
          <PhoneInput
            country={"us"}
            value={phoneno ? phoneno : phone}
            onChange={isKYCDONE ? null : (phoneNumber) => setPhone(phoneNumber)}
          />
        </div>
        {phoneno && !isKYCDONE && (
          <div className="m-t-2">
            <p className="m-b-0_5">Enter OTP</p>
            <input
              type="text"
              placeholder="Enter OTP"
              className="input-email-log otp"
              value={verifyOTP}
              onChange={(e) => setVerifyOTP(e.target.value)}
            />
          </div>
        )}

        <div>
          {!isKYCDONE && !phoneno && (
            <button
              className="button-save t-a-c m-t-2"
              onClick={Sendotptonumber}
            >
              Save & Continue
            </button>
          )}

          {!isKYCDONE && phoneno && (
            <button
              className="button-save t-a-c m-t-2"
              onClick={Verifyotpandcompletekyc}
            >
              Verify
            </button>
          )}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
