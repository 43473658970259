import React from "react";

export default function Support() {
  return (
    <>
    <div className="row m-t-2">
    <div className="col-lg-5 col-sm-12 col-12 col-md-5">
      <p className="f-w-800">Reach Out to Us</p>
      <p className="c-gr">
       We love to hear from you and we will <br/> always be available to help!
      </p>
    </div>
    <div className="col-lg-5 col-sm-12 col-12 col-md-5">
      <div>
        <button className="button-send">Send us an email</button>
      </div>
    </div>
  </div>
    </>
  );
}
