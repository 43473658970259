// import Zendusd from "./component/pages/Zendusd";
import Navbar from "./component/pages/Navbar";
import "./component/scss/Main.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./component/pages/Dashboard";
import Page404 from "./component/pages/Page404";
import Register from "./component/pages/Register";
import Login from "./component/pages/Login";
import Sidenavbar from "./component/pages/Sidenavbar";
import Transaction from "./component/pages/Transaction";
import Assets from "./component/pages/Assets";
import Swap from "./component/pages/Swap";
import Withdraw from "./component/pages/Withdraw";
import Transfer from "./component/pages/Transfer";
import Prepaid from "./component/pages/Prepaid";
import Accountsetting from "./component/pages/Accountsetting";
import Comingsoon from "./component/pages/Comingsoon";
import Cardcoin from "./component/pages/Cardcoin";
import Sendotp from "./component/pages/Sendotp";
import Transactiondetail from "./component/pages/Transactiondetail";
import Depositetoke from "./component/pages/Depositetoke";
import VerifyloginOTP from "./component/pages/VerifyloginOTP";
import ResetMessage from "./component/pages/ResetMessage";
import ForgetPassword from "./component/pages/ForgetPassword";
import ResetPassword from "./component/pages/ResetPassword";
import ConfirmSwap from "./component/pages/ConfirmSwap";
import Withdrawassets from "./component/pages/Withdrawassets";
import TransferToken from "./component/pages/TransferToken";
import Depositetokenmatic from "./component/pages/Depositetokenmatic";
import Adminpanel from "./component/Admin/Adminpanel";
import "./App.scss";
import { useEffect, useState } from "react";
import Userdetail from "./component/Admin/Userdetail";
import AdminLogin from "./component/Admin/AdminLogin";
import AdminForget from "./component/Admin/AdminForget";
import AdminSigup from "./component/Admin/AdminSingup";
import {
  getAccount,
  GetChainId,
  MetaMasklogin,
} from "./component/Web3/walletconnect";
import SwapHistory from "./component/pages/SwapHistory";
// import Deposite from "./component/pages/Deposite";

// const DB_API = "https://pastwalletbackend.up.railway.app";

const DB_API = "http://localhost:2000";

function App() {
  // ---------dark---------mode----------------
  const [theme, setTheme] = useState("light-theme"); // Change the initial state value
  const [account, setAccount] = useState("");

  const toggleThemelight = () => {
    theme === "dark-theme" ? setTheme("light-theme") : setTheme("dark-theme");
    window.localStorage.setItem(
      "theme",
      theme === "dark-theme" ? "light-theme" : "dark-theme"
    );
  };

  const toggleThemedark = () => {
    theme === "dark-theme" ? setTheme("light-theme") : setTheme("dark-theme");
    window.localStorage.setItem(
      "theme",
      theme === "dark-theme" ? "light-theme" : "dark-theme"
    );
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const Metamask = async () => {
    await MetaMasklogin();
    const add = await getAccount();
    window.localStorage.setItem("wallet", "wallet");
    setAccount(add);
  };
  useEffect(() => {
    const init = async () => {
      const wallet = window.localStorage.getItem("wallet");
      if (wallet) {
        Metamask();
      }
      const id = await GetChainId();
      if (Number(id) != 80001) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x13881" }], // chainId must be in hexadecimal numbers
        });
      }
    };

    init();
  }, []);

  return (
    <div className="">
      <Router>
        <Navbar
          theme={theme}
          toggleThemedark={toggleThemedark}
          toggleThemelight={toggleThemelight}
        />
        <Routes>
          <Route path="/" element={<Login DB_API={DB_API} theme={theme} />} />
          <Route
            path="/verification"
            element={<VerifyloginOTP DB_API={DB_API} theme={theme} />}
          />

          <Route
            path="/sendotp"
            element={<Sendotp DB_API={DB_API} theme={theme} />}
          />

          <Route
            path="/register"
            element={<Register DB_API={DB_API} theme={theme} />}
          />

          <Route
            path="/forget-password"
            element={<ForgetPassword DB_API={DB_API} theme={theme} />}
          />
          <Route
            path="/reset-link-sent-to-mail"
            element={<ResetMessage theme={theme} />}
          />
          <Route
            path="/reset-your-password/:token"
            element={<ResetPassword DB_API={DB_API} theme={theme} />}
          />

          <Route path="/sidebar" element={<Sidenavbar theme={theme} />} />

          <Route
            path="/dashboard"
            element={
              <Dashboard
                DB_API={DB_API}
                theme={theme}
                toggleThemedark={toggleThemedark}
                toggleThemelight={toggleThemelight}
                Metamask={Metamask}
                account={account}
              />
            }
          />

          <Route
            path="/transaction"
            element={<Transaction theme={theme} DB_API={DB_API} />}
          />
          <Route
            path="/assets"
            element={<Assets theme={theme} DB_API={DB_API} />}
          />
          {/* <Route path="/deposite" element={<Deposite theme={theme} />} /> */}
          <Route path="/" element={<Depositetoke theme={theme} />} />
          <Route
            path="/swap"
            element={<Swap theme={theme} DB_API={DB_API} />}
          />
          {/* <Route path="/withdraw" element={<Withdraw theme={theme} />} /> */}
          <Route
            path="/transfer"
            element={<Transfer DB_API={DB_API} theme={theme} />}
          />

          <Route path="/prepaid" element={<Prepaid theme={theme} />} />
          <Route path="/card" element={<Comingsoon theme={theme} />} />
          <Route path="/cardcoin" element={<Cardcoin theme={theme} />} />

          <Route
            path="/accountsetting"
            element={<Accountsetting DB_API={DB_API} theme={theme} />}
          />

          <Route path="*" element={<Page404 theme={theme} />} />
          <Route
            path="/tansactiondetail"
            element={<Transactiondetail theme={theme} DB_API={DB_API} />}
          />
          <Route
            path="/swap-history"
            element={<SwapHistory theme={theme} DB_API={DB_API} />}
          />
          <Route
            path="/depositetoken"
            element={<Depositetoke DB_API={DB_API} theme={theme} />}
          />
          {/* <Route path="/swaptoken" element={<SwapToken theme={theme} />} /> */}
          <Route path="/confirmswap" element={<ConfirmSwap theme={theme} />} />
          <Route
            path="/withdrawassets"
            element={
              <Withdrawassets theme={theme} DB_API={DB_API} account={account} />
            }
          />
          <Route
            path="/transfertoken"
            element={<TransferToken theme={theme} />}
          />
          <Route
            path="/tokenmatic"
            element={<Depositetokenmatic theme={theme} />}
          />
          <Route
            path="/adminlogin"
            element={<AdminLogin theme={theme} DB_API={DB_API} />}
          />
          <Route path="/adminsignup" element={<AdminSigup theme={theme} />} />
          <Route
            path="/adminpanel"
            element={<Adminpanel theme={theme} DB_API={DB_API} />}
          />
          <Route
            path="/admin/forget-password"
            element={<AdminForget theme={theme} DB_API={DB_API} />}
          />
          <Route
            path="/user/:email"
            element={<Userdetail theme={theme} DB_API={DB_API} />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
