import Web3 from "web3/dist/web3.min.js";

import {
  Testnet_Token_ABI,
  Testnet_Token_Contract_Address,
} from "../Contract/tokenContractAbi";
import {
  Testnet_ABI,
  Testnet_Contract_Address,
} from "../Contract/contractandabi";

let web3 = new Web3(window.ethereum);
console.log("web3", web3);

export const getAccount = async () => {
  try {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    return accounts[0];
  } catch (error) {
    console.error("Error getting account:", error);
  }
};

export const MetaMasklogin = async () => {
  try {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    return accounts[0];
  } catch (error) {
    console.error("Error logging in with MetaMask:", error);
  }
};

export const getContract = async (abi, address) => {
  try {
    const Contract = new web3.eth.Contract(abi, address);
    return Contract;
  } catch (error) {
    console.error("Error creating contract instance:", error);
  }
};

export const GetChainId = async () => {
  try {
    const id = await web3.eth.net.getId();
    console.log("id", id);
    return id;
  } catch (error) {
    console.error("Error getting chain ID:", error);
  }
};

export const Allow = async () => {
  try {
    const contract = new web3.eth.Contract(
      Testnet_Token_ABI,
      Testnet_Token_Contract_Address
    );
    console.log("allow contract", contract);
    const data = await contract.methods
      .allowance(await getAccount(), Testnet_Contract_Address)
      .call();
    return data;
  } catch (error) {
    console.error("Error while allowing token:", error);
  }
};

export const Approve = async () => {
  try {
    const contract = new web3.eth.Contract(
      Testnet_Token_ABI,
      Testnet_Token_Contract_Address
    );
    console.log("approve contract", contract);
    const data = await contract.methods
      .approve(
        Testnet_Contract_Address,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935"
      )
      .send({ from: await getAccount() });
    return data;
  } catch (error) {
    console.error("Error while approving token:", error);
  }
};
export const towie = async (amount) => {
  try {
    let convertedAmount;

    // Check if the token is USDC
    if ("USDC") {
      // Convert the amount to Wei with 6 decimal places
      convertedAmount = web3.utils.toWei(amount.toString(), "mwei");
      console.log("convertedAmountMwei:", convertedAmount);
    } else {
      // Use the default conversion for other tokens
      convertedAmount = await web3.utils.toWei(amount.toString(), "ether");
      console.log("convertedAmountEther:", convertedAmount);
    }

    return convertedAmount;
  } catch (error) {
    console.log("error towie :", error);
  }
};

export const Deposit_Tokens = async (_tokenAddress, _amount) => {
  try {
    // Check if the token is allowed
    const allow = await Allow();
    console.log("allow:", allow);
    const enterAmount = await towie(_amount);
    console.log("enterAmount:", enterAmount);
    // Get the contract instance
    const contract = await getContract(Testnet_ABI, Testnet_Contract_Address);
    console.log("contract:", contract);
    const allowance = Number(allow);
    if (allowance > 0) {
      const data = await contract.methods
        .depositToken(_tokenAddress, enterAmount)
        .send({ from: await getAccount() });
      return data;
    } else {
      // If allowance is 0, approve the token
      const approvalData = await Approve();
      // Check if the approval transaction was successful
      if (approvalData.status) {
        const depositData = await contract.methods
          .depositToken(_tokenAddress, enterAmount)
          .send({ from: await getAccount() });

        // Return the transaction data
        return depositData;
      } else {
        // Handle approval failure
        console.error("Token approval failed.");
      }
    }
  } catch (error) {
    // Handle errors during the deposit process
    console.error("Error while depositing token:", error);
  }
};

export const Swap_Token = async (_tokenIn, _tokenOut, _amountIn, _router) => {
  try {
    // Check if the token is allowed
    const allow = await Allow();
    const enterAmount = await towie(_amountIn);

    // Get the contract instance
    const contract = await getContract(Testnet_ABI, Testnet_Contract_Address);
    const allowance = Number(allow);
    if (allowance > 0) {
      const data = await contract.methods
        .swapTokenForToken(_tokenIn, _tokenOut, enterAmount, _router)
        .send({ from: await getAccount() });
      return data;
    } else {
      // If allowance is 0, approve the token
      const approvalData = await Approve();
      // Check if the approval transaction was successful
      if (approvalData.status) {
        const swapData = await contract.methods
          .swapTokenForToken(_tokenIn, _tokenOut, enterAmount, _router)
          .send({ from: await getAccount() });

        // Return the transaction data
        return swapData;
      } else {
        // Handle approval failure
        console.error("Token approval failed.");
      }
    }
  } catch (error) {
    console.error("Error while swapping token:", error);
  }
};

export const Get_Wallet_Balance = async (_tokenAddress, _walletOwner) => {
  try {
    const contract = await getContract(Testnet_ABI, Testnet_Contract_Address);
    console.log("contract:", contract);
    const data = await contract.methods
      .getWalletBalance(_tokenAddress, _walletOwner)
      .call({ from: await getAccount() });
    console.log("data:", data[0]);
    const convertedBalance = await towie(data[0]);
    return convertedBalance / 10 ** 6;
  } catch (error) {
    console.error("Error while swapping token:", error);
  }
};

export const Withdraw_Tokens = async (_tokenAddress, _amount) => {
  try {
    // Check if the token is allowed
    const allow = await Allow();
    console.log("allow:", allow);
    const enterAmount = await towie(_amount);
    console.log("enterAmount:", enterAmount);
    // Get the contract instance
    const contract = await getContract(Testnet_ABI, Testnet_Contract_Address);
    console.log("contract:", contract);
    const allowance = Number(allow);
    if (allowance > 0) {
      const data = await contract.methods
        .withdrawToken(_tokenAddress, enterAmount)
        .send({ from: await getAccount() });
      return data;
    } else {
      // If allowance is 0, approve the token
      const approvalData = await Approve();
      // Check if the approval transaction was successful
      if (approvalData.status) {
        const withdrawData = await contract.methods
          .withdrawToken(_tokenAddress, enterAmount)
          .send({ from: await getAccount() });

        // Return the transaction data
        return withdrawData;
      } else {
        // Handle approval failure
        console.error("Token approval failed.");
      }
    }
  } catch (error) {
    console.error("Error while withdrawing token:", error);
    // Check if the error is due to user rejection of MetaMask transaction
    if (
      error.code === -32603 &&
      error.message.includes("User denied transaction")
    ) {
      throw new Error("User denied transaction");
    } else {
      // Rethrow the error if it's not related to user rejection
      throw error;
    }
  }
};
