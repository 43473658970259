import React, { useState } from "react";
import registerimg from "../../Assets/Image/auth-bg.png";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./Loader";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../Assets/logo/pastewallet2.png";
import darklogo from "../../Assets/logo/pastewallet1.png";

export default function Login({ DB_API, theme }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const Verify_OTP = async () => {
    if (!email || !password) {
      toast.warn("Fields cannot be empty !");
    }
    setLoading(true);
    try {
      const { data } = await axios.post(`${DB_API}/api/sendotpandupdate`, {
        email: email,
        password: password,
      });
      toast.success(data.message);
      localStorage.setItem("email", data.email);
      localStorage.setItem("number", data.phonenumber);
      if (data.message) {
        setTimeout(() => {
          navigate("/verification");
        }, 3000);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="container-new pa-1">
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="create-bg">
              <div className="d-f j-c-s-b m-t-1">
                <div className="t-a-c ">
                  <div className="image-dog1">
                    {theme != "dark-theme" ? (
                      <a href="/dashboard">
                        <img src={logo} alt="" className="logoimg" />
                      </a>
                    ) : (
                      <a href="/dashboard">
                        <img src={darklogo} alt="" className="logoimg" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="pa-4">
                <h3 className="f-s-2 f-w-800 ">Log in</h3>

                <div className="form">
                  <div className="m-t-2">
                    <p className="m-b-0_5">Enter Email</p>
                    <input
                      type="email"
                      placeholder="Enter email"
                      className="input-email-log"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="m-t-2">
                    <p className="m-b-0_5">Enter your password</p>
                    <input
                      type="password"
                      placeholder="Enter your password"
                      className="input-email-log"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <p className="m-t-0_5 m-b-0_5 log f-s-1_25 f-w-600">
                      <a href="/forget-password" target="_blank">
                        Forget Password
                      </a>
                    </p>
                  </div>
                  <div>
                    <button
                      className="w-70 m-t-1 m-b-1 f-w-600 create-btn"
                      onClick={Verify_OTP}
                    >
                      Login
                    </button>
                  </div>
                  <div>
                    <p className="f-s-1_3">
                      Don't have an account ?
                      <NavLink to="/sendotp">
                        <span className="log">Create one</span>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 login-hide">
            <div className="registerimg-div p-t-5">
              <img src={registerimg} className="w-100" alt="" />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}
