import { Grid } from "@mui/material";
import React from "react";
import Sidenavbar from "./Sidenavbar";
import comingsoon from "../../Assets/Image/27282.jpg";
import avatar from "../../Assets/Image/zendwallet-avatar-removebg-preview.png"

export default function Prepaid() {
  return (
    <>
      <div>
        <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
            {" "}
            {/* <div>
              <img src={comingsoon} className="h-in-v" />
            </div> */}
            <div>
                <div className="d-f j-c-s-b m-b-2">
                  <div>
                    <h1>Deposite</h1>
                    <p>Good morning ntech</p>
                  </div>
                  <div className="d-f">
                    {" "}
                    {/*   <Form className="m-t-1">
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      label=""
                    />
                  </Form> */}
                    <div className="t-a-c">
                      <img src={avatar} className="w-25 b-r-25 " />
                    </div>
                  </div>
                </div>
              </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
