import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
  Tabs,
  Tab,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs({ DB_API }) {
  const [value, setValue] = useState(0);
  const [rows, setRows] = useState([]);
  const [withdrawArray, setWithdrawArray] = useState([]);
  const [depositArray, setDepositArray] = useState([]);
  const [successfullTx, setSuccessfulTransactions] = useState([]);
  const [failedTx, setFailedTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const FetchingEmail = localStorage.getItem("email");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Fetching_User_Transactions = async () => {
    try {
      const { data } = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: FetchingEmail,
      });

      const depositRows = [];
      const withdrawRows = [];
      const successfulTransactions = [];
      const failedTransactions = [];

      // Process deposit transactions
      data.findingUserData.token_transcation_history_deposit.forEach(
        (transaction) => {
          const tokenname = transaction.tokenname || transaction.tokenName;
          const txId = transaction.transactionId;
          const Status = transaction.status;
          const tokenAmount =
            transaction.tokenamount || transaction.enterAmount;
          depositRows.push({ txId, tokenname, Status, tokenAmount });

          // Categorize by success status
          if (Status && Status.toLowerCase() === "success") {
            successfulTransactions.push({
              txId,
              tokenname,
              Status,
              tokenAmount,
            });
          } else {
            failedTransactions.push({ txId, tokenname, Status, tokenAmount });
          }
        }
      );

      // Process withdraw transactions
      data.findingUserData.token_transcation_history_withdraw.forEach(
        (transaction) => {
          const tokenname = transaction.tokenName;
          const txId = transaction.transactionId;
          const Status = transaction.status;
          const tokenAmount = transaction.receivedAmount;
          withdrawRows.push({ txId, tokenname, Status, tokenAmount });

          // Categorize by success status
          if (Status && Status.toLowerCase() === "success") {
            successfulTransactions.push({
              txId,
              tokenname,
              Status,
              tokenAmount,
            });
          } else {
            failedTransactions.push({ txId, tokenname, Status, tokenAmount });
          }
        }
      );

      // Combine deposit and withdraw rows
      const rows = depositRows.concat(withdrawRows);

      setRows(rows);
      setWithdrawArray(withdrawRows);
      setDepositArray(depositRows);
      setSuccessfulTransactions(successfulTransactions);
      setFailedTransactions(failedTransactions);
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    Fetching_User_Transactions();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="All" className="fund" {...a11yProps(0)} />
        <Tab label="Withdraw" className="fund" {...a11yProps(1)} />
        <Tab label="Deposite" className="fund" {...a11yProps(2)} />
        <Tab label="Successful" className="fund" {...a11yProps(3)} />
        <Tab label="Failed" className="fund" {...a11yProps(4)} />
      </Tabs>

      <CustomTabPanel value={value} index={0}>
        {rows.length ? (
          <TableContainer component={Paper}>
            <Table aria-label="transactions table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">SNO</TableCell>
                  <TableCell align="center">Transaction ID</TableCell>
                  <TableCell align="center">Token Name</TableCell>
                  <TableCell align="center">Token Amount</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}.
                      </TableCell>
                      <TableCell align="center">
                        {row.txId || row.transactionId}
                      </TableCell>
                      <TableCell align="center">
                        {row.tokenname || row.tokenName}
                      </TableCell>
                      <TableCell align="center">
                        {row.tokenamount ||
                          row.tokenAmount ||
                          row.receivedAmount}
                      </TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            color:
                              (row.Status &&
                                row.Status.toLowerCase() === "success") ||
                              (row.status &&
                                row.status.toLowerCase() === "success") ||
                              row.successful === true
                                ? "green"
                                : "red",
                          }}
                        >
                          {row.Status ||
                            row.status ||
                            (row.successful === true ? "success" : "failed")}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="row">
            <div>
              <div className="t-a-c m-t-7">
                <h1>No Transactions Yet</h1>
                <p>
                  This Place is empty because you haven't done any transaction
                </p>
              </div>
            </div>
          </div>
        )}

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {withdrawArray.length ? (
          <TableContainer component={Paper}>
            <Table aria-label="transactions table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">SNO</TableCell>
                  <TableCell align="center">Transaction ID</TableCell>
                  <TableCell align="center">Token Name</TableCell>
                  <TableCell align="center">Token Amount</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {withdrawArray
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}.
                      </TableCell>
                      <TableCell align="center">
                        {row.txId || row.transactionId}
                      </TableCell>
                      <TableCell align="center">
                        {row.tokenname || row.tokenName}
                      </TableCell>
                      <TableCell align="center">
                        {row.tokenamount ||
                          row.tokenAmount ||
                          row.receivedAmount}
                      </TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            color:
                              (row.Status &&
                                row.Status.toLowerCase() === "success") ||
                              (row.status &&
                                row.status.toLowerCase() === "success") ||
                              row.successful === true
                                ? "green"
                                : "red",
                          }}
                        >
                          {row.Status ||
                            row.status ||
                            (row.successful === true ? "success" : "failed")}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="row">
            <div>
              <div className="t-a-c m-t-7">
                <h1>No Transactions Yet</h1>
                <p>
                  This Place is empty because you haven't done any withdraw
                  transaction
                </p>
              </div>
            </div>
          </div>
        )}

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {depositArray.length ? (
          <TableContainer component={Paper}>
            <Table aria-label="transactions table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">SNO</TableCell>
                  <TableCell align="center">Transaction ID</TableCell>
                  <TableCell align="center">Token Name</TableCell>
                  <TableCell align="center">Token Amount</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {depositArray
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}.
                      </TableCell>
                      <TableCell align="center">
                        {row.txId || row.transactionId}
                      </TableCell>
                      <TableCell align="center">
                        {row.tokenname || row.tokenName}
                      </TableCell>
                      <TableCell align="center">
                        {row.tokenamount ||
                          row.tokenAmount ||
                          row.receivedAmount}
                      </TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            color:
                              (row.Status &&
                                row.Status.toLowerCase() === "success") ||
                              (row.status &&
                                row.status.toLowerCase() === "success") ||
                              row.successful === true
                                ? "green"
                                : "red",
                          }}
                        >
                          {row.Status ||
                            row.status ||
                            (row.successful === true ? "success" : "failed")}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="row">
            <div>
              <div className="t-a-c m-t-7">
                <h1>No Transactions Yet</h1>
                <p>
                  This Place is empty because you haven't done any deposit
                  transaction
                </p>
              </div>
            </div>
          </div>
        )}

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {successfullTx.length ? (
          <TableContainer component={Paper}>
            <Table aria-label="transactions table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">SNO</TableCell>
                  <TableCell align="center">Transaction ID</TableCell>
                  <TableCell align="center">Token Name</TableCell>
                  <TableCell align="center">Token Amount</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {successfullTx
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}.
                      </TableCell>
                      <TableCell align="center">
                        {row.txId || row.transactionId}
                      </TableCell>
                      <TableCell align="center">
                        {row.tokenname || row.tokenName}
                      </TableCell>
                      <TableCell align="center">
                        {row.tokenamount ||
                          row.tokenAmount ||
                          row.receivedAmount}
                      </TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            color:
                              (row.Status &&
                                row.Status.toLowerCase() === "success") ||
                              (row.status &&
                                row.status.toLowerCase() === "success") ||
                              row.successful === true
                                ? "green"
                                : "red",
                          }}
                        >
                          {row.Status ||
                            row.status ||
                            (row.successful === true ? "success" : "failed")}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="row">
            <div>
              <div className="t-a-c m-t-7">
                <h1>No Transactions Yet</h1>
                <p>
                  This Place is empty because you haven't done any Successfull
                  transaction
                </p>
              </div>
            </div>
          </div>
        )}

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        {failedTx.length ? (
          <TableContainer component={Paper}>
            <Table aria-label="transactions table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">SNO</TableCell>
                  <TableCell align="center">Transaction ID</TableCell>
                  <TableCell align="center">Token Name</TableCell>
                  <TableCell align="center">Token Amount</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {failedTx
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}.
                      </TableCell>
                      <TableCell align="center">
                        {row.txId || row.transactionId}
                      </TableCell>
                      <TableCell align="center">
                        {row.tokenname || row.tokenName}
                      </TableCell>
                      <TableCell align="center">
                        {row.tokenamount ||
                          row.tokenAmount ||
                          row.receivedAmount}
                      </TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            color:
                              (row.Status &&
                                row.Status.toLowerCase() === "success") ||
                              (row.status &&
                                row.status.toLowerCase() === "success") ||
                              row.successful === true
                                ? "green"
                                : "red",
                          }}
                        >
                          {row.Status ||
                            row.status ||
                            (row.successful === true ? "success" : "failed")}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="row">
            <div>
              <div className="t-a-c m-t-7">
                <h1>No Transactions Yet</h1>
                <p>
                  This Place is empty because you haven't done any Failed
                  transaction
                </p>
              </div>
            </div>
          </div>
        )}

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CustomTabPanel>
    </Box>
  );
}

export default BasicTabs;
