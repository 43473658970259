import { Grid } from "@mui/material";
import React from "react";
import Sidenavbar from "./Sidenavbar";

import avatar from "../../Assets/Image/zendwallet-avatar-removebg-preview.png";
import { IoIosArrowRoundBack } from "react-icons/io";
import polygonimg from "../../Assets/Image/polygon.png";
import bitcoinimg from "../../Assets/Image/bitcoin.png";
import { CgArrowsExchange } from "react-icons/cg";
import { NavLink } from "react-router-dom";

export default function SwapToken({ theme }) {
  return (
    <>
      <div>
        <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar theme={theme} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
            {" "}
            <div>
              <div>
                <div className="d-f j-c-e m-b-2">
                  <div className="d-f">
                    {" "}
                    {/*   <Form className="m-t-1">
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      label=""
                    />
                  </Form> */}
                    <div className="t-a-c">
                      <img src={avatar} className="w-25 b-r-25 " />
                    </div>
                  </div>
                </div>
                {/* Box-deposite----------- */}
                <div className="">
                  <div className="deposite-child">
                    <div>
                      <NavLink to="/swap">
                        <div className="c-p">
                          <IoIosArrowRoundBack className="m-r-0_5 b-c-t" />
                          Back
                        </div>
                      </NavLink>
                      <div className="t-a-c m-t-3">
                        <h4 className="f-w-600">Confirm Swap</h4>
                        <p>Kindly confirm this transaction</p>
                        <div className="m-t-2">
                          <img src={bitcoinimg} className="w-10 m-r-2" />
                          <CgArrowsExchange className="b-c-t f-s-1_5" />
                          <img src={polygonimg} className="w-10 m-l-2" />
                        </div>
                      </div>
                      <div className="d-f j-c-s-b">
                        <p className="f-w-600">Token </p>
                        <p className="f-w-600">Balance</p>
                      </div>
                      <div className="">
                        <NavLink to="">
                          {" "}
                          <div className="token-tab c-p m-t-1">
                            <div className="d-f j-c-s-b">
                              <div className="d-f m-t-0_5">
                                <p className=" m-l-0_5">From</p>
                              </div>
                              <div>
                                <p className="f-s-1_25 f-w-600">0 BTC</p>
                              </div>
                            </div>
                            <div>
                              <p className="m-t-0_5 m-l-0_5">Fee</p>
                              <p className="m-l-0_5 m-t-0_5">
                                Total Value to receive
                              </p>
                            </div>
                          </div>
                          <div className="m-t-1 t-a-c">
                            <p>Rate</p>
                          </div>
                          <div className="t-a-c">
                            <p>Fetch new rates in 1s</p>
                            <div className="d-f j-c-c">
                              <button className="button-blue  m-t-1">
                                Swap Tokens
                              </button>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
