import React, { useState } from "react";
import registerimg from "../../Assets/Image/auth-bg.png";
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import logo from "../../Assets/logo/pastewallet2.png";
import darklogo from "../../Assets/logo/pastewallet1.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ResetPassword({ DB_API, theme }) {
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();

  const navigate = useNavigate();

  const { token } = useParams();
  const FetchingEmail = localStorage.getItem("email");

  const Reset_Password = async () => {
    if (newPassword !== confirmNewPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }
    try {
      const { data } = await axios.put(
        `${DB_API}/api/reset-password/${token}`,
        {
          email: FetchingEmail,
          newPassword: newPassword,
        }
      );
      toast.success(data.message);
      if (data.message) {
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
    }
  };

  return (
    <div>
      <div className="container-new pa-1">
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="create-bg">
              <div className="d-f j-c-s-b m-t-1">
                <div className="image-dog1">
                  {theme != "dark-theme" ? (
                    <a href="/dashboard">
                      <img src={logo} alt="" className="logoimg" />
                    </a>
                  ) : (
                    <a href="/dashboard">
                      <img src={darklogo} alt="" className="logoimg" />
                    </a>
                  )}
                </div>
              </div>
              <div className="pa-4">
                <h3 className="f-s-2 f-w-800 ">Reset Password</h3>
                <p className="sub-ti">
                  Your new password must be different from the previous
                  password.
                </p>
                <div className="form">
                  <div className="m-t-2">
                    <p className="m-b-0_5">Enter Email</p>
                    <input
                      type="email"
                      placeholder="Enter email"
                      className="input-email-log"
                      value={FetchingEmail}
                    />
                  </div>
                  <div className="m-t-2">
                    <p className="m-b-0_5">Enter New Password</p>
                    <input
                      type="password"
                      placeholder="Enter new password"
                      className="input-email-log"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div className="m-t-2">
                    <p className="m-b-0_5">Enter Confirm Password</p>
                    <input
                      type="password"
                      placeholder="Enter confirmation password"
                      className="input-email-log"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                  </div>
                  <div>
                    <button
                      className="w-70 m-t-1 m-b-1 f-w-600 create-btn"
                      onClick={Reset_Password}
                    >
                      Submit
                    </button>
                  </div>
                  <div>
                    <p className="f-s-1_3">
                      Remeber your password ?
                      <NavLink to="/">
                        <span className="log">Login</span>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 login-hide">
            <div className="registerimg-div p-t-5">
              <img src={registerimg} className="w-100" alt="" />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}
