import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidenavbar from "../pages/Sidenavbar";
import avatar from "../../Assets/Image/zendwallet-avatar-removebg-preview.png";
import axios from "axios";
import { Link } from "react-router-dom";

//................Function to get the greeting based on the provided hour...............//
function getGreetingByHour(hour) {
  let greeting = "";

  if (hour >= 0 && hour < 12) {
    greeting = "Good Morning";
  } else if (hour >= 12 && hour < 17) {
    greeting = "Good Afternoon";
  } else if (hour >= 17 && hour < 20) {
    greeting = "Good Evening";
  } else {
    greeting = "Good Night";
  }
  return `${greeting}`;
}

// Get the user's detected timezone
const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Get the current hour in the user's detected timezone
const currentTime = new Date(
  new Date().toLocaleString("en-US", { timeZone: userTimezone })
);
const userHour = currentTime.getHours();

const timeGreeting = getGreetingByHour(userHour);

export default function Adminpanel({ theme, DB_API }) {
  const [userData, setUserData] = useState([]);

  const Fetching_Global_User_Data = async () => {
    try {
      const { data } = await axios.get(`${DB_API}/api/fetchinguser`);
      setUserData(data.fetchingUser);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      Fetching_Global_User_Data();
    }, 1500);
    return () => clearInterval(intervalId);
  }, [userData]);

  return (
    <>
      <div>
        <div>
          <div>
            <div className="d-f j-c-e m-b-2">
              <div className="d-f">
                <div className="t-a-c">
                  <img src={avatar} className="w-25 b-r-25 " />
                </div>
              </div>
            </div>

            <div className="t-a-c">
              <h3>Admin Dashboard</h3>
            </div>
            {/* <div className="d-f j-c-s-b m-b-2">
              <div>
                <h1>Welcome ! Admin</h1>
                <p>{timeGreeting}</p>
              </div>
            </div> */}

            <div className="t-a-c">
              <h3>User Data</h3>
            </div>

            <div className="container">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">SNO.</TableCell>
                      <TableCell align="center">Username</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Detail</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData &&
                      userData.map((res, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="center">{index + 1}.</TableCell>
                            <TableCell align="center">{res.username}</TableCell>
                            <TableCell align="center">{res.email}</TableCell>
                            <TableCell align="center">
                              <Link to={`/user/${res.email}`}>Check</Link>
                              {/* <div className="status_border">
                                    {isSuspend ? (
                                      <button className="status_staus_access">
                                        UnSuspend
                                      </button>
                                    ) : (
                                      <button className="status_staus_access">
                                        Suspend
                                      </button>
                                    )}
                                  </div> */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        {/* <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar theme={theme} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
 
          </Grid>
        </Grid> */}
      </div>
    </>
  );
}
