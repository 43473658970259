import React from "react";
import Kyctabs from "./Kyctabs";

export default function Kyc({ DB_API }) {
  return (
    <>
      {" "}
      <div className="row m-t-2">
        <div className="col-lg-5 col-sm-12 col-12 col-md-5">
          <p className="f-w-800">KYC Verification</p>
          <p className="c-gr">
            The KYC Verification will be done in 2 steps,
            <br />
            once you complete your KYC
          </p>
        </div>
        <div className="col-lg-5 col-sm-12 col-12 col-md-5">
          <div>
            <Kyctabs DB_API={DB_API} />
          </div>
        </div>
      </div>
    </>
  );
}
