import React, { useEffect } from "react";
import profile from "../../Assets/Image/zendwallet-avatar-removebg-preview.png";
import Country from "./Country";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useState } from "react";
import Loader from "./Loader";

export default function Personalprofile({ DB_API }) {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [gender, setGender] = useState();
  const [birthDate, setBirthDate] = useState();
  const [houseAddress, setHouseAddress] = useState();
  const [country, setCountry] = useState();
  const [isKYCDONE, setIsKYCDONE] = useState(false);
  const [loading, setLoading] = useState(false);

  const FetchingEmail = localStorage.getItem("email");
  const FetchingUsername = localStorage.getItem("username");

  const Update_Profile = async () => {
    console.log("Updating profile...");
    setLoading(true);
    if (
      firstName ||
      lastName ||
      gender ||
      birthDate ||
      houseAddress ||
      country
    ) {
      try {
        const { data } = await axios.put(`${DB_API}/api/updateprofile`, {
          email: FetchingEmail,
          firstname: firstName,
          lastname: lastName,
          gender: gender,
          dateofbirth: birthDate,
          address: houseAddress,
          country: country,
        });
        toast.success(data.message);
      } catch (error) {
        toast.error("Failed to update profile");
      } finally {
        setLoading(false);
      }
    } else {
      toast.warn("Please fill in at least one field to update the profile.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const UserDataInterval = setInterval(() => {
      Fetch_User_Profile_Data();
    }, 4000);
    return () => {
      clearInterval(UserDataInterval);
    };
  }, []);

  const Fetch_User_Profile_Data = async () => {
    try {
      const { data } = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: FetchingEmail,
      });
      setFirstName(data.findingUserData.firstname || "");
      setLastName(data.findingUserData.lastname || "");
      setGender(data.findingUserData.gender || "");
      setBirthDate(data.findingUserData.dateofbirth || "");
      setHouseAddress(data.findingUserData.address || "");
      setCountry(data.findingUserData.country || "");
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const kycInterval = setInterval(async () => {
      const { data } = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: FetchingEmail,
      });
      setIsKYCDONE(data.findingUserData.isKYCDone);
    }, 1000);
    return () => {
      clearInterval(kycInterval);
    };
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-4 col-12">
          <div className="d-g">
            <div>
              <img src={profile} className="w-50" alt="" />
            </div>
            <div className="m-t-2">
              <div>
                {firstName} {lastName}
              </div>
              <div>
                @{FetchingUsername}
                {isKYCDONE ? (
                  <span className="verified">Verified</span>
                ) : (
                  <span className="unverified">Unverified</span>
                )}
                <p>Joined on: Sep 29, 2023 2:33 PM</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-4 col-12">
          <div className="m-t-2">
            <p>First Name</p>
            <input
              placeholder="N-tech"
              className="input-profile"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="m-t-2">
            <p>Username</p>
            <input
              placeholder="username"
              className="input-profile"
              value={FetchingUsername}
            />
          </div>
          <div className="m-t-2">
            <p>Gender</p>
            <select
              className="input-profile"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="m-t-2">
            <p>House Address</p>
            <textarea
              placeholder="Provide Address"
              className="input-profile"
              rows="5"
              cols="50"
              value={houseAddress}
              onChange={(e) => setHouseAddress(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4 col-12">
          <div className="m-t-2">
            <p>Last Name</p>
            <input
              placeholder="last-name"
              className="input-profile"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="m-t-2">
            <p>E-mail</p>
            <input
              placeholder="e-mail"
              className="input-profile"
              value={FetchingEmail}
            />
          </div>

          <div className="m-t-2">
            <p>Date of birth</p>
            <input
              placeholder="dd-mm-yy"
              className="input-profile"
              type="date"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
            />
          </div>

          <div className="m-t-2 country">
            <p>Country</p>
            <select
              className="input-profile"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              {Country &&
                Country.map((country, index) => {
                  return <option key={index}>{country}</option>;
                })}
            </select>
          </div>
          <div className="">
            <button
              className="button-save w-80  m-t-2"
              onClick={Update_Profile}
            >
              Save Changes
            </button>
          </div>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}
