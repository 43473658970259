import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid } from "@mui/material";
import Sidenavbar from "./Sidenavbar";
import avatar from "../../Assets/Image/zendwallet-avatar-removebg-preview.png";
import { MdKeyboardArrowRight } from "react-icons/md";
import { CgArrowsExchange } from "react-icons/cg";
import Tokens_List from "./Listoftokens";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";

const Transfer = ({ DB_API, theme }) => {
  const [amount, setAmount] = useState();
  const [availableBalance, setAvailableBalance] = useState();
  const [selectedTokenData, setSelectedTokenData] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);
  const [maxBalance, setMaxBalance] = useState();
  const [mode, setMode] = useState("funding");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const FetchingEmail = localStorage.getItem("email");

  const Fetching_User_Balance = async () => {
    try {
      const { data } = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: FetchingEmail,
      });
      setData(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleTokenChange = async (event) => {
    const selectedToken = event.target.value;
    setSelectedToken(selectedToken);
    setMaxBalance(0);

    if (mode === "funding") {
      // Find the corresponding token amount from the fetched data
      const depositTokenData = data?.findingUserData.deposit_token;

      const tokenAmountDeposit = {};
      let tokenFound = false;

      for (let index = 0; index < depositTokenData.length; index++) {
        const element = depositTokenData[index].tokenamount;
        const tokenName = depositTokenData[index].tokenname;

        // If token name already exists, add the amount, otherwise initialize it
        if (tokenName === selectedToken) {
          tokenAmountDeposit[tokenName] = element;
          setAvailableBalance(element);
          setSelectedTokenData(tokenName);
          tokenFound = true;
        }
      }
      if (!tokenFound) {
        toast.error(`Please deposit the amount in ${selectedToken} first.`);
        setMaxBalance(0);
        setAvailableBalance(0);
        setAmount(0);
        setSelectedTokenData(undefined);
      }
    } else {
      const tradingTokenData = data?.findingUserData.trading_token;

      const tokenAmountTrading = {};
      let tokenFound = false;

      for (let index = 0; index < tradingTokenData.length; index++) {
        const element = tradingTokenData[index].tokenamount;
        const tokenName = tradingTokenData[index].tokenname;

        // If token name already exists, add the amount, otherwise initialize it
        if (tokenName === selectedToken) {
          tokenAmountTrading[tokenName] = element;
          setAvailableBalance(element);
          setSelectedTokenData(tokenName);
          tokenFound = true;
        }
      }

      if (!tokenFound) {
        toast.error(`Please deposit the amount in ${selectedToken} first.`);
        setMaxBalance(0);
        setAvailableBalance(0);
        setAmount(0);
        setSelectedTokenData(undefined);
      }
    }
  };

  useEffect(() => {
    Fetching_User_Balance();
  }, []);

  const transferFundingToTrading = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${DB_API}/api/transfertokenfortrading`,
        {
          email: FetchingEmail,
          tokenname: selectedTokenData,
          tokenamount:
            Number(amount) || Number(availableBalance) || Number(maxBalance),
          currencyname: "USD",
        }
      );
      toast.success(data.message);
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const transferTradingToFunding = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${DB_API}/api/transfertokenforfunding`,
        {
          email: FetchingEmail,
          tokenname: selectedTokenData,
          tokenamount:
            Number(amount) || Number(availableBalance) || Number(maxBalance),
          currencyname: "USD",
        }
      );
      toast.success(data.message);
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const Transfer_Balance = () => {
    // Check the updated mode and call the appropriate transfer function
    if (mode === "funding") {
      transferFundingToTrading();
    } else {
      transferTradingToFunding();
    }
  };

  const Fake_Button = () => {
    toast.warning("Fields cannot be empty!");
  };

  const handleMaxButtonClick = async () => {
    try {
      setMaxBalance(availableBalance);
    } catch (error) {
      toast.error(error);
    }
  };

  const toggleMode = () => {
    setMode((prevMode) => (prevMode === "funding" ? "transfer" : "funding"));
    setMaxBalance(0);
    setAvailableBalance(0);
    setAmount(0);
    setSelectedTokenData(undefined);
    setSelectedToken(undefined);
  };

  return (
    <>
      <div>
        <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar theme={theme} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
            <div>
              <div className="d-f j-c-e m-b-2">
                <div className="d-f">
                  <div className="t-a-c">
                    <img src={avatar} className="w-25 b-r-25 " alt="avatar" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="swap-child">
                <div>
                  <div className="t-a-c m-t-3 m-b-1">
                    <h4 className="f-w-600 ">Transfer Assets</h4>
                    <p>Select the asset you want to transfer</p>
                  </div>
                </div>
                {loading && <Loader />}
                <div className="token-tab d-f">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="transfer_area">
                      <div className="d-f">
                        <p className="m-r-4">From:</p>
                        <p className="d-f bo-bot">
                          {mode === "funding" ? "Funding" : "Trading"}
                          <p className="m-l-4">
                            <MdKeyboardArrowRight className="b-c-t" />
                          </p>
                        </p>
                        <hr />
                      </div>
                      <div className="d-f">
                        <p className="m-r-5_5">To:</p>
                        <p className="d-f">
                          {mode === "funding" ? "Trading" : "Funding"}
                          <p className="m-l-4">
                            <MdKeyboardArrowRight className="b-c-t" />
                          </p>
                        </p>
                        <hr />
                      </div>
                    </div>
                    <div className="t-a-c c-p">
                      <CgArrowsExchange
                        className="exchange-swap f-s-2 transfer"
                        onClick={toggleMode}
                      />
                    </div>
                  </div>
                </div>

                <label className="eth-la-la">Select Token</label>
                <select
                  className="token-tab w-100 m-t-1 ps-r"
                  id="sel1"
                  placeholder="Select"
                  name="Select"
                  onChange={handleTokenChange}
                >
                  <option value="" disabled selected>
                    Select Token
                  </option>
                  {Tokens_List &&
                    Tokens_List.map((token, index) => (
                      <option key={index} value={token.name}>
                        {token.name}
                      </option>
                    ))}
                </select>

                <div className="m-t-1">
                  <label className="eth-la">
                    Enter the amount you want to transfer
                  </label>
                  <input
                    className="eth-in"
                    placeholder="0"
                    type="number"
                    value={amount || maxBalance}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      setMaxBalance(e.target.value);
                    }}
                  />
                </div>
                <div className="d-f j-c-s-b m-t-1">
                  <p>
                    Avail: {availableBalance ? availableBalance : 0}${" "}
                    {selectedTokenData}
                  </p>

                  <button className="swap-bu" onClick={handleMaxButtonClick}>
                    Max
                  </button>
                </div>
                {amount || selectedTokenData ? (
                  <div className="m-t-2">
                    <button
                      className="button-blue w-100 pa-1"
                      onClick={Transfer_Balance}
                    >
                      Transfer Token
                    </button>
                  </div>
                ) : (
                  <div className="m-t-2">
                    <button
                      className="button-blue w-100 pa-1 disabled"
                      onClick={Fake_Button}
                    >
                      Transfer Token
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
};

export default Transfer;
