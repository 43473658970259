const Tokens_List = [
  {
    name: "USDC",
    symbol: "usdc",
    image:
      "https://assets.coingecko.com/coins/images/6319/large/usdc.png?1696506694",
    address: "0x0FA8781a83E46826621b3BC094Ea2A0212e71B23",
    decimalPlaces: 6,
    marketPrice: 0,
    priceChange24h: 0,
    priceChangePercent24h: 0,
  },
  {
    name: "WMATIC",
    symbol: "wmatic",
    image:
      "https://assets.coingecko.com/coins/images/14073/large/matic.png?1696513797",
    address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    decimalPlaces: 18,
    marketPrice: 0,
    priceChange24h: 0,
    priceChangePercent24h: 0,
  },
  {
    name: "WBTC",
    symbol: "wbtc",
    image:
      "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png?1696507857",
    address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    decimalPlaces: 18,
    marketPrice: 0,
    priceChange24h: 0,
    priceChangePercent24h: 0,
  },
  {
    name: "WETH",
    symbol: "weth",
    image:
      "https://assets.coingecko.com/coins/images/2518/large/weth.png?1696503332",
    address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    decimalPlaces: 18,
    marketPrice: 0,
    priceChange24h: 0,
    priceChangePercent24h: 0,
  },
  {
    name: "Solana",
    symbol: "sol",
    image:
      "https://assets.coingecko.com/coins/images/4128/large/solana.png?1696504756",
    address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    decimalPlaces: 18,
    marketPrice: 0,
    priceChange24h: 0,
    priceChangePercent24h: 0,
  },
  {
    name: "Polygon",
    symbol: "matic",
    image:
      "https://assets.coingecko.com/coins/images/4713/large/polygon.png?1698233745",
    address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    decimalPlaces: 18,
    marketPrice: 0,
    priceChange24h: 0,
    priceChangePercent24h: 0,
  },
  {
    name: "Tether",
    symbol: "usdt",
    image:
      "https://assets.coingecko.com/coins/images/325/large/Tether.png?1696501661",
    address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    decimalPlaces: 18,
    marketPrice: 0,
    priceChange24h: 0,
    priceChangePercent24h: 0,
  },
  {
    name: "Dai",
    symbol: "dai",
    image:
      "https://assets.coingecko.com/coins/images/9956/large/Badge_Dai.png?1696509996",
    address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    decimalPlaces: 18,
    marketPrice: 0,
    priceChange24h: 0,
    priceChangePercent24h: 0,
  },
];

export default Tokens_List;
