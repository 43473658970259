import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";

export default function Security({ DB_API }) {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [transactionpin, setTransactionPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [isTxPin, setIsTxPin] = useState(false);

  const [currentPin, setCurrentPin] = useState("");
  const [newPin, setNewPin] = useState("");

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const FetchingEmail = localStorage.getItem("email");

  useEffect(() => {
    const txpin = async () => {
      const { data } = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: FetchingEmail,
      });
      setIsTxPin(data.findingUserData.isTxPin);
    };
    txpin();
  }, [isTxPin]);

  const Change_Password = async () => {
    try {
      const { data } = await axios.put(`${DB_API}/api/change-password`, {
        email: FetchingEmail,
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      });
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
    }
  };

  const Create_Tx_Pin = async () => {
    try {
      if (!transactionpin || !confirmPin) {
        toast.warn("Field cannot be empty");
      }

      if (transactionpin !== confirmPin) {
        toast.error("Pin does not match");
      }
      if (transactionpin.length < 5) {
        toast.warn("Pin should of 5 digit");
      } else {
        setLoading(true);
        const { data } = await axios.put(`${DB_API}/api/txpin`, {
          email: FetchingEmail,
          transactionpin: transactionpin,
        });
        toast.success(data.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const Change_Tx_Pin = async () => {
    try {
      if (!currentPin || !newPin) {
        toast.warn("Field cannot be empty");
      } else {
        setLoading(true);
        const { data } = await axios.put(`${DB_API}/api/txpin`, {
          email: FetchingEmail,
          currentPin: currentPin,
          newPin: newPin,
        });
        toast.success(data.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const Delete_Account = async () => {
    try {
      const { data } = await axios.delete(`${DB_API}/api/deleteaccount`, {
        data: { email: FetchingEmail }, // Use 'data' instead of passing it directly
      });

      await localStorage.removeItem("email");
      await localStorage.removeItem("username");
      await localStorage.removeItem("token");

      toast.success(data.message);

      // Navigate to the sendotp page
      navigate("/sendotp");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div>
        <div className="row m-t-2">
          <div className="col-lg-5 col-sm-12 col-12 col-md-5">
            <p className="f-w-800">Change Password</p>
            <p className="c-gr">
              Change the unique password that protect
              <br /> your account
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 col-12 col-md-5">
            <div>
              <button className="button-send" onClick={handleShow}>
                Change Password
              </button>
            </div>
          </div>
        </div>
        {!isTxPin ? (
          <div className="row m-t-3">
            <div className="col-lg-5 col-sm-12 col-12 col-md-5">
              <p className="f-w-800">Transaction Pin</p>
              <p className="c-gr">
                create your Transaction pin and gain
                <br /> confidence
              </p>
            </div>
            <div className="col-lg-5 col-sm-12 col-12 col-md-5">
              <div>
                <button className="button-send" onClick={handleShow2}>
                  Create Transaction Pin
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row m-t-3">
            <div className="col-lg-5 col-sm-12 col-12 col-md-5">
              <p className="f-w-800">Change Pin</p>
              <p className="c-gr">
                change your Transaction pin and gain
                <br /> confidence
              </p>
            </div>
            <div className="col-lg-5 col-sm-12 col-12 col-md-5">
              <div>
                <button className="button-send" onClick={handleShow3}>
                  Change Transaction Pin
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="row m-t-3">
          <div className="col-lg-5 col-sm-12 col-12 col-md-5">
            <p className="f-w-800">Delete Account</p>
            <p className="c-gr">
              Erase your zend wallet account and all your
              <br />
              information
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 col-12 col-md-5">
            <div>
              <button className="button-send" onClick={handleShow4}>
                Delete Your Account
              </button>
            </div>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body className="t-a-c ">
            <p className="f-w-600 f-s-1_5">Change Password</p>
            <p>
              Change your password, to avoid intruders accessing your account
            </p>
          </Modal.Body>
          <Modal.Body className="t-a-l ">
            <p className="f-w-400">Email</p>
            <div className="d-f">
              <input
                type="email"
                placeholder="Enter Current password"
                className="search-de m-t-0_5"
                value={FetchingEmail}
              />
            </div>
            <p className="f-w-400">Current Password</p>
            <div className="d-f">
              <input
                type="password"
                placeholder="Enter Current password"
                className="search-de m-t-0_5"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Body className="t-a-l ">
            <p className="f-w-400">New Password</p>
            <div className="d-f">
              <input
                type="password"
                placeholder="Enter New password"
                className="search-de m-t-0_5"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Body className="t-a-l ">
            <p className="f-w-400">Confirm Password</p>
            <div className="d-f">
              <input
                type="password"
                placeholder="Confirm your password"
                className="search-de m-t-0_5"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </Modal.Body>

          <Modal.Footer className="t-a-c j-c-c">
            <div className="t-a-c">
              <button className="button-blue w-100" onClick={Change_Password}>
                Change Password
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* ---------------------------------------------------Modal-2----------------------- */}
        {loading && <Loader />}
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body className="t-a-c">
            <p className="f-w-600 f-s-1_5">Create Transaction Pin</p>
            <p>Enter the details below to create your transaction pin</p>
          </Modal.Body>
          <Modal.Body className="t-a-l">
            <div className="m-t-2">
              <p className="m-b-0_5">Enter Transcation Pin</p>
              <input
                type="text"
                placeholder="Enter transaction pin"
                className="input-email-log"
                value={transactionpin}
                maxLength={5}
                onChange={(e) => setTransactionPin(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Body className="t-a-l">
            <div className="m-t-2">
              <p className="m-b-0_5">Enter Confirm Transcation Pin</p>
              <input
                type="password"
                placeholder="Enter confirm transaction pin"
                className="input-email-log"
                value={confirmPin}
                maxLength={5}
                onChange={(e) => setConfirmPin(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="t-a-c j-c-c">
            <div className="t-a-c">
              <button className="button-blue w-100" onClick={Create_Tx_Pin}>
                Create Pin
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ---------------------------------------------------Modal-3----------------------- */}

        <Modal
          backdrop="static"
          keyboard={false}
          show={show3}
          onHide={handleClose3}
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body className="t-a-c">
            <p className="f-w-600 f-s-1_5">Change Current Transaction Pin</p>
            <p>
              Enter the details below to change your current transaction pin
            </p>
          </Modal.Body>
          <Modal.Body className="t-a-l">
            <div className="m-t-2">
              <p className="m-b-0_5">Enter Current Pin</p>
              <input
                type="text"
                placeholder="Enter current transaction pin"
                className="input-email-log"
                value={currentPin}
                maxLength={5}
                onChange={(e) => setCurrentPin(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Body className="t-a-l">
            <div className="m-t-2">
              <p className="m-b-0_5">Enter New Pin</p>
              <input
                type="password"
                placeholder="Enter new transaction pin"
                className="input-email-log"
                value={newPin}
                maxLength={5}
                onChange={(e) => setNewPin(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="t-a-c j-c-c">
            <div className="t-a-c">
              <button className="button-blue w-100" onClick={Change_Tx_Pin}>
                Change Pin
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        {/*---------------- Modal-4------------------ */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal title</Modal.Title> */}
          </Modal.Header>
          <div className="t-a-c">
            <RiDeleteBin6Line className="f-s-2 key m-t-1" />
          </div>
          <Modal.Body className="t-a-c ">
            <p className="f-w-600 f-s-1_5">Are you sure?</p>
            <p>
              Once your account has been deleted, you will not be able to
              retrieve it. Please withdraw your funds before deleting.
            </p>
          </Modal.Body>
          <Modal.Footer className="t-a-c j-c-c">
            <div className="t-a-c">
              <button className="button-blue w-100" onClick={Delete_Account}>
                Yes, Delete now
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
}
