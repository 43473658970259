import { Grid } from "@mui/material";
import React, { useState } from "react";
import Sidenavbar from "./Sidenavbar";
import avatar from "../../Assets/Image/zendwallet-avatar-removebg-preview.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tokens_List from "./Listoftokens";
import Loader from "./Loader";
import { Deposit_Tokens, Get_Wallet_Balance } from "../Web3/walletconnect";
import axios from "axios";

export default function Depositetoke({ DB_API, theme }) {
  const [amount, setAmount] = useState();
  const [currency, setCurrency] = useState("USD");
  const [selectedToken, setSelectedToken] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const FetchingEmail = localStorage.getItem("email");

  const handleTokenChange = (e) => {
    const selectedTokenName = e.target.value;
    setSelectedToken(selectedTokenName);

    // Find the corresponding token address based on the selected token name
    const selectedTokenObject = Tokens_List.find(
      (token) => token.name === selectedTokenName
    );
    if (selectedTokenObject) {
      setTokenAddress(selectedTokenObject.address);
    } else {
      setTokenAddress("");
    }
  };

  const fetchTokenName = async (tokenAddress) => {
    try {
      // Assuming you have a function to fetch the token name based on the token address
      const token = Tokens_List.find((t) => t.address === tokenAddress);
      console.log("token :", token);
      return token ? token.name : null;
    } catch (error) {
      console.error("Error fetching token name:", error);
      return null;
    }
  };

  const Deposit_Token = async () => {
    try {
      setLoading(true);
      const convertedAmount = Number(amount);
      const tokenName = await fetchTokenName(tokenAddress);
      if (selectedToken) {
        const depositTransaction = await Deposit_Tokens(
          tokenAddress,
          convertedAmount
        );
        if (depositTransaction) {
          const { data } = await axios.post(`${DB_API}/api/deposittoken`, {
            email: FetchingEmail,
            tokenamount: convertedAmount,
            tokenname: tokenName,
            currencyname: currency,
          });
          toast.success(data.message);
          setLoading(false);
        }
      } else {
        console.error("Error fetching token name.");
        toast.error("Error fetching token name.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error depositing token:", error);
      toast.error(error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const Deposit_Token_Tx_History = async () => {
    try {
      const convertedAmount = Number(amount);
      // const tokenName = await fetchTokenName(tokenAddress);
      if (selectedToken) {
        // Store the selected token name and other relevant details in the database
        const { data } = await axios.put(
          `${DB_API}/api/transcationhistorydeposit`,
          {
            email: FetchingEmail,
            tokenamount: convertedAmount,
            tokenname: selectedToken,
            currencyname: currency,
          }
        );
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handleDeposit = () => {
    Deposit_Token();
    Deposit_Token_Tx_History();
  };

  return (
    <>
      <div>
        {loading && <Loader />}

        <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar theme={theme} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
            <div>
              <div>
                <div className="d-f j-c-e m-b-2">
                  <div className="d-f">
                    <div className="t-a-c">
                      <img src={avatar} className="w-25 b-r-25 " />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="swap-child">
                    <div>
                      <div className="t-a-c m-t-3 m-b-1">
                        <h4 className="f-w-600 ">Deposit Token</h4>
                        <p>Select the token you want to deposit</p>
                      </div>
                    </div>

                    <div className="m-t-1">
                      <p>Currency</p>
                      <input
                        className="eth-in"
                        placeholder="0"
                        type="text"
                        value={currency}
                      />
                    </div>
                    <div className="m-t-2 country">
                      <p>Token Name</p>
                      <select
                        className="eth-in"
                        value={selectedToken}
                        onChange={handleTokenChange}
                      >
                        <option value="">Select a token</option>
                        {Tokens_List.map((token, index) => (
                          <option key={index} value={token.name}>
                            {token.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="m-t-1">
                      <input
                        className="eth-in"
                        placeholder="0"
                        type="text"
                        value={tokenAddress}
                      />
                    </div>

                    {tokenAddress && (
                      <div className="m-t-1">
                        <p> Enter the amount you want to transfer</p>

                        <input
                          className="eth-in"
                          placeholder="0"
                          type="number"
                          value={Number(amount)}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                    )}

                    <div className="m-t-2">
                      <button
                        className="button-blue w-100 pa-1"
                        onClick={handleDeposit}
                      >
                        Deposit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
}
