import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import Tokens_List from "./Listoftokens";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import ProgressBar from "./ProgressBar";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ DB_API }) {
  const [value, setValue] = React.useState(0);
  const [tokenFundingAmounts, setTokenFundingAmounts] = useState({});
  const [tokenTradingAmounts, setTokenTradingAmounts] = useState({});
  const [withdrawTokenAmounts, setWithdrawTokenAmounts] = useState({});
  const [loading, setLoading] = useState(true);

  const FetchingEmail = localStorage.getItem("email");

  useEffect(() => {
    getTokenAmount();
  }, []);

  const getTokenAmount = async () => {
    try {
      const { data } = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: FetchingEmail,
      });

      //............FOR Funding..........//

      const fundingarray = data.findingUserData.deposit_token;
      const tokenAmountFundingObject = {};

      for (let index = 0; index < fundingarray.length; index++) {
        const element = fundingarray[index].tokenamount;
        const tokenName = fundingarray[index].tokenname;

        // If token name already exists, add the amount, otherwise initialize it
        if (tokenAmountFundingObject[tokenName]) {
          tokenAmountFundingObject[tokenName] += element;
        } else {
          tokenAmountFundingObject[tokenName] = element;
        }
      }
      //............FOR TRADING..........//

      const tradingarray = data.findingUserData.trading_token;
      const tokenAmountTradingObject = {};

      for (let index = 0; index < tradingarray.length; index++) {
        const element = tradingarray[index].tokenamount;
        const tokenName = tradingarray[index].tokenname;

        // If token name already exists, add the amount, otherwise initialize it
        if (tokenAmountTradingObject[tokenName]) {
          tokenAmountTradingObject[tokenName] += element;
        } else {
          tokenAmountTradingObject[tokenName] = element;
        }
      }
      //............FOR WITHDRAW TOKEN..........//

      const withdrawTokens = data.findingUserData.swap_token;
      const withdrawTokensObject = {};

      for (let index = 0; index < withdrawTokens.length; index++) {
        const element = withdrawTokens[index].receivedAmount;
        const tokenName = withdrawTokens[index].toToken;

        // If token name already exists, add the amount, otherwise initialize it
        if (withdrawTokensObject[tokenName]) {
          withdrawTokensObject[tokenName] += element;
        } else {
          withdrawTokensObject[tokenName] = element;
        }
      }

      setTokenFundingAmounts(tokenAmountFundingObject);
      setTokenTradingAmounts(tokenAmountTradingObject);
      setWithdrawTokenAmounts(withdrawTokensObject);
    } catch (error) {
      toast.error(error);
    }
  };

  const Fetching_Token_Details = async () => {
    try {
      const { data } = await axios.get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en"
      );
      const USDC = Tokens_List.find(
        (token) => token.name.toLowerCase() === "usdc"
      );
      if (USDC) {
        USDC.marketPrice = data[6].current_price;
        USDC.priceChange24h = data[6].price_change_24h;
        USDC.priceChangePercent24h = data[6].price_change_percentage_24h;
      }
      const WBTC = Tokens_List.find(
        (token) => token.name.toLowerCase() === "wbtc"
      );
      if (WBTC) {
        WBTC.marketPrice = data[16].current_price;
        WBTC.priceChange24h = data[16].price_change_24h;
        WBTC.priceChangePercent24h = data[16].price_change_percentage_24h;
      }

      const SOL = Tokens_List.find(
        (token) => token.name.toLowerCase() === "solana"
      );
      if (SOL) {
        SOL.marketPrice = data[4].current_price;
        SOL.priceChange24h = data[4].price_change_24h;
        SOL.priceChangePercent24h = data[4].price_change_percentage_24h;
      }

      const PolygonMatic = Tokens_List.find(
        (token) => token.name.toLowerCase() === "polygon"
      );
      if (PolygonMatic) {
        PolygonMatic.marketPrice = data[14].current_price;
        PolygonMatic.priceChange24h = data[14].price_change_24h;
        PolygonMatic.priceChangePercent24h =
          data[14].price_change_percentage_24h;
      }

      const USDT = Tokens_List.find(
        (token) => token.name.toLowerCase() === "tether"
      );
      if (USDT) {
        USDT.marketPrice = data[2].current_price;
        USDT.priceChange24h = data[2].price_change_24h;
        USDT.priceChangePercent24h = data[2].price_change_percentage_24h;
      }
      const Dai = Tokens_List.find(
        (token) => token.name.toLowerCase() === "dai"
      );
      if (Dai) {
        Dai.marketPrice = data[20].current_price;
        Dai.priceChange24h = data[20].price_change_24h;
        Dai.priceChangePercent24h = data[20].price_change_percentage_24h;
      }
      return Tokens_List;
    } catch (error) {
      toast.error(error);
    }
  };

  const Price_WMATIC = async () => {
    try {
      const { data } = await axios.get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=wmatic&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en"
      );

      const wmaticToken = Tokens_List.find(
        (token) => token.name.toLowerCase() === "wmatic"
      );
      if (wmaticToken) {
        wmaticToken.marketPrice = data[0].current_price;
        wmaticToken.priceChange24h = data[0].price_change_24h;
        wmaticToken.priceChangePercent24h = data[0].price_change_percentage_24h;
      }
      return wmaticToken;
    } catch (error) {
      toast.error(error);
    }
  };

  const Price_WETH = async () => {
    try {
      const { data } = await axios.get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=weth&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en"
      );

      const wethToken = Tokens_List.find(
        (token) => token.name.toLowerCase() === "weth"
      );
      if (wethToken) {
        wethToken.marketPrice = data[0].current_price;
        wethToken.priceChange24h = data[0].price_change_24h;
        wethToken.priceChangePercent24h = data[0].price_change_percentage_24h;
      }

      return wethToken;
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tokenDetails, wmaticDetails, wethDetails] = await Promise.all([
          Fetching_Token_Details(),
          Price_WMATIC(),
          Price_WETH(),
        ]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="bo-bottom"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab className="fund" label="Funding Balance" {...a11yProps(0)} />
            <Tab className="fund" label="Trading Balance" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div className="d-f j-c-e">
            <NavLink to="/transfer">
              <button className="button-blue w-7">Transfer</button>
            </NavLink>
          </div>

          {/* Funding Balance */}
          <div>
            <div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Image</TableCell>
                      <TableCell align="center">Token Name</TableCell>
                      <TableCell align="center">Market Price</TableCell>
                      <TableCell align="center">Price Change 24h</TableCell>
                      <TableCell align="center">
                        Price Change Percentage 24h
                      </TableCell>
                      <TableCell align="center">Funding Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          Loading...
                        </TableCell>
                      </TableRow>
                    ) : (
                      Tokens_List.map((token) => (
                        <TableRow
                          key={token.name}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center" component="th" scope="row">
                            <Avatar
                              alt="Remy Sharp"
                              src={token.image}
                              style={{ display: "block", margin: "auto" }}
                            />
                          </TableCell>

                          <TableCell align="center" component="th" scope="row">
                            {token.name} ({token.symbol})
                          </TableCell>

                          <TableCell align="center">
                            ${token.marketPrice}
                          </TableCell>
                          <TableCell align="center">
                            {token.priceChange24h > 0 ? (
                              <span style={{ color: "green" }}>
                                <BiSolidUpArrow style={{ color: "green" }} />
                                {token.priceChange24h.toFixed(2) === 0.0
                                  ? token.priceChange24h
                                  : token.priceChange24h.toFixed(4)}
                                %
                              </span>
                            ) : token.priceChange24h < 0 ? (
                              <span style={{ color: "red" }}>
                                <BiSolidDownArrow style={{ color: "red" }} />{" "}
                                {token.priceChange24h.toFixed(2) === 0.0
                                  ? token.priceChange24h
                                  : token.priceChange24h.toFixed(4)}
                                %
                              </span>
                            ) : (
                              <span>{token.priceChange24h.toFixed(2)}%</span>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {token.priceChangePercent24h > 0 ? (
                              <span style={{ color: "green" }}>
                                <BiSolidUpArrow style={{ color: "green" }} />
                                {token.priceChangePercent24h.toFixed(2) === 0.0
                                  ? token.priceChangePercent24h
                                  : token.priceChangePercent24h.toFixed(4)}
                                %
                              </span>
                            ) : token.priceChangePercent24h < 0 ? (
                              <span style={{ color: "red" }}>
                                <BiSolidDownArrow style={{ color: "red" }} />
                                {token.priceChangePercent24h.toFixed(2) === 0.0
                                  ? token.priceChangePercent24h
                                  : token.priceChangePercent24h.toFixed(4)}
                                %
                              </span>
                            ) : (
                              <span>
                                {token.priceChangePercent24h.toFixed(2)}%
                              </span>
                            )}
                          </TableCell>

                          <TableCell align="center">
                            {tokenFundingAmounts[token.name]
                              ? `${tokenFundingAmounts[token.name].toFixed(2)}$`
                              : "0$"}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <hr className="mo-sc-view" />
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="d-f j-c-e">
            <NavLink to="/transfer">
              <button className="button-blue w-7">Transfer</button>
            </NavLink>
          </div>
          {/* Trading Balance */}
          <div>
            <div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Image</TableCell>
                      <TableCell align="center">Token Name</TableCell>
                      <TableCell align="center">Market Price</TableCell>
                      <TableCell align="center">Price Change 24h</TableCell>
                      <TableCell align="center">
                        Price Change Percentage 24h
                      </TableCell>
                      <TableCell align="center">Trading Balance</TableCell>
                      <TableCell align="center">Withdraw Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <ProgressBar />
                        </TableCell>
                      </TableRow>
                    ) : (
                      Tokens_List.map((token) => (
                        <TableRow
                          key={token.name}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center" component="th" scope="row">
                            <Avatar
                              alt="Remy Sharp"
                              src={token.image}
                              style={{ display: "block", margin: "auto" }}
                            />
                          </TableCell>

                          <TableCell align="center" component="th" scope="row">
                            {token.name} ({token.symbol})
                          </TableCell>

                          <TableCell align="center">
                            ${token.marketPrice}
                          </TableCell>
                          <TableCell align="center">
                            {token.priceChange24h > 0 ? (
                              <span style={{ color: "green" }}>
                                <BiSolidUpArrow style={{ color: "green" }} />
                                {token.priceChange24h.toFixed(2) === 0.0
                                  ? token.priceChange24h
                                  : token.priceChange24h.toFixed(4)}
                                %
                              </span>
                            ) : token.priceChange24h < 0 ? (
                              <span style={{ color: "red" }}>
                                <BiSolidDownArrow style={{ color: "red" }} />{" "}
                                {token.priceChange24h.toFixed(2) === 0.0
                                  ? token.priceChange24h
                                  : token.priceChange24h.toFixed(4)}
                                %
                              </span>
                            ) : (
                              <span>{token.priceChange24h.toFixed(2)}%</span>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {token.priceChangePercent24h > 0 ? (
                              <span style={{ color: "green" }}>
                                <BiSolidUpArrow style={{ color: "green" }} />
                                {token.priceChangePercent24h.toFixed(2) === 0.0
                                  ? token.priceChangePercent24h
                                  : token.priceChangePercent24h.toFixed(4)}
                                %
                              </span>
                            ) : token.priceChangePercent24h < 0 ? (
                              <span style={{ color: "red" }}>
                                <BiSolidDownArrow style={{ color: "red" }} />
                                {token.priceChangePercent24h.toFixed(2) === 0.0
                                  ? token.priceChangePercent24h
                                  : token.priceChangePercent24h.toFixed(4)}
                                %
                              </span>
                            ) : (
                              <span>
                                {token.priceChangePercent24h.toFixed(2)}%
                              </span>
                            )}
                          </TableCell>

                          <TableCell align="center">
                            {tokenTradingAmounts[token.name]
                              ? `${tokenTradingAmounts[token.name].toFixed(2)}$`
                              : "$0"}
                          </TableCell>
                          <TableCell align="center">
                            {withdrawTokenAmounts[token.name]
                              ? `${withdrawTokenAmounts[token.name].toFixed(
                                  5
                                )}$`
                              : "$0"}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <hr className="mo-sc-view" />
            </div>
          </div>
        </CustomTabPanel>
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
