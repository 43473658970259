import React, { useState } from "react";
import registerimg from "../../Assets/Image/auth-bg.png";
import Form from "react-bootstrap/Form";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Loader from "./Loader";
import logo from "../../Assets/logo/pastewallet2.png";
import darklogo from "../../Assets/logo/pastewallet1.png";

export default function Register({ DB_API, theme }) {
  const [otp, setOTP] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const FetchingEmail = localStorage.getItem("email");

  const navigate = useNavigate();

  const create_account = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${DB_API}/api/signin`, {
        email: FetchingEmail,
        otp: otp,
        firstname: firstName,
        lastname: lastName,
        username: username,
        password: password,
      });
      toast.success(data.message);
      const userName = data.user.username;
      localStorage.setItem("username", userName);
      if (data.message) {
        setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="container-new pa-1">
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="create-bg">
              <div className="d-f j-c-s-b m-t-1">
                <div className="image-dog1">
                  {theme != "dark-theme" ? (
                    <a href="/dashboard">
                      <img src={logo} alt="" className="logoimg" />
                    </a>
                  ) : (
                    <a href="/dashboard">
                      <img src={darklogo} alt="" className="logoimg" />
                    </a>
                  )}
                </div>
              </div>
              <div className="pa-4">
                <h3 className="f-s-2 f-w-800 ">
                  Verify OTP and Create Account
                </h3>
                <p className="sub-ti">Kindly provide the information we need</p>
                <div className="form">
                  <div className="m-t-2">
                    <p className="m-b-0_5">Enter OTP</p>
                    <input
                      type="text"
                      placeholder="Enter your otp"
                      className="input-email"
                      value={otp}
                      onChange={(e) => setOTP(e.target.value)}
                    />
                  </div>
                  <div className="m-t-2">
                    <p className="m-b-0_5">Email</p>
                    <input
                      type="text"
                      value={FetchingEmail ? FetchingEmail : null}
                      placeholder="Enter your email"
                      className="input-email"
                    />
                  </div>
                  <div className="row m-t-1">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div>
                        <p>First Name</p>
                        <input
                          type="text"
                          placeholder=" Enter first name"
                          className="firstplace m-t-0_5"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div>
                        <p>Last Name</p>
                        <input
                          type="text"
                          placeholder="Enter last name"
                          className="firstplace m-t-0_5"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row m-t-1">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div>
                        <p>Username</p>
                        <input
                          type="text"
                          placeholder=" Enter your username"
                          className="firstplace m-t-0_5"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div>
                        <p>Password</p>
                        <input
                          type="password"
                          placeholder="Create password"
                          className="firstplace m-t-0_5"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row m-t-1">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                      <div>
                        <p>Referral code (Optional)</p>
                        <input
                          placeholder="Provide your referral code"
                          className="firstplace m-t-0_5"
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="d-f m-t-1">
                    <div>
                      <Form.Check
                        aria-label="option 1"
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <p className="m-l-0_5">
                      I agree to private policy, cookies policy and terms and
                      conditions.
                    </p>
                  </div>
                  {isChecked ? (
                    <div className="t-a-c">
                      <button
                        className="w-70 m-t-1 m-b-1 f-w-600 create-disable-btn"
                        disabled={true}
                      >
                        Create Your Account
                      </button>
                    </div> // Only show the button if the checkbox is checked
                  ) : (
                    <div className="t-a-c">
                      <button
                        className="w-70 m-t-1 m-b-1 f-w-600 create-btn"
                        onClick={create_account}
                      >
                        Create Your Account
                      </button>
                    </div>
                  )}
                  <div>
                    <p className="f-s-1_3 t-a-c">
                      Already have an account?{" "}
                      <NavLink to="/">
                        <span className="log c-p">Log In</span>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 login-hide">
            <div className="registerimg-div p-t-5">
              <img src={registerimg} className="w-100" />
            </div>

            <p></p>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}
