import React from "react";
import commingsoon from "../../Assets/Image/27282.jpg";
import { Grid } from "@mui/material";
import Sidenavbar from "./Sidenavbar";

export default function Comingsoon({ theme }) {
  return (
    <>
      <div>
        <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar theme={theme} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
            {" "}
            <img src={commingsoon} className="" />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
