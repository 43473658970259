import { Grid } from "@mui/material";
import React from "react";
import Sidenavbar from "./Sidenavbar";
import avatar from "../../Assets/Image/zendwallet-avatar-removebg-preview.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import tetherimg from "../../Assets/Image/tether.png";
import ethimg from "../../Assets/Image/ethereum.png";
import usdcimg from "../../Assets/Image/usdc.png";
import tronimg from "../../Assets/Image/tron.png";
import solanaimg from "../../Assets/Image/solana.png";
import bitcoinimg from "../../Assets/Image/bitcoin.png";
import rippleimg from "../../Assets/Image/ripple.png";
import alogimg from "../../Assets/Image/algorand.png";
import bitcoinsimg from "../../Assets/Image/bitcoin.png";
import polyimg from "../../Assets/Image/polygon.png";
import avalanchimg from "../../Assets/Image/avalanche.png";
import stellarimg from "../../Assets/Image/stellar.png";
import liteimg from "../../Assets/Image/litecoin.png";
import dogimg from "../../Assets/Image/dogecoin-doge-logo.png";
import okximg from "../../Assets/Image/okb.png";
import { NavLink } from "react-router-dom";
import { IoIosArrowForward,IoIosArrowRoundBack } from "react-icons/io";

export default function Depositetokenmatic() {
  return (
    <>
      <div>
        <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
            {" "}
            <div>
              <div>
                <div className="d-f j-c-e m-b-2">
                  <div className="d-f">
                    {" "}
                    {/*   <Form className="m-t-1">
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label=""
                      />
                    </Form> */}
                    <div className="t-a-c">
                      <img src={avatar} className="w-25 b-r-25 " />
                    </div>
                  </div>
                </div>
                {/* Box-deposite----------- */}
                <div className="">
                  <div className="deposite-child">
                    <div>
                      <div className="c-p">
                        <IoIosArrowRoundBack className="m-r-0_5 b-c-t" />
                        Back
                      </div>
                      <div className="t-a-c m-t-3">
                        <h4 className="f-w-600">
                          Deposite Token <IoIosArrowForward className="b-c-t" />{" "}
                          MATIC
                        </h4>
                        <p>Select Network</p>
                      </div>

                      <div className="m-t-2">
                        <p>Email</p>
                        <input
                          className="search-de w-100 m-t-1 m-b-1"
                          placeholder="Email"
                        />
                      </div>
                      <div className="m-t-1">
                        <p>Enter Amount</p>
                        <input
                          className="search-de w-100 m-t-1 m-b-1"
                          placeholder="Enter amount to deposite "
                        />
                      </div>

                      <div className="">
                        <p>Currency</p>
                        <label className="eth-la-la">Select Token</label>
                        <select
                          class="token-tab w-100 m-t-1 ps-r"
                          id="sel1"
                          placeholder="Select "
                          name="Select"
                        >
                          <option>MATIC</option>
                          <option>USDT</option>
                          <option>Tither</option>
                        </select>
                      </div>
                      <div><button className="button-blue w-100 m-t-1">Submit</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
