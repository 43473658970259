import React, { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import Sidenavbar from "./Sidenavbar";
import avatar from "../../Assets/Image/zendwallet-avatar-removebg-preview.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CgArrowsExchange } from "react-icons/cg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Tokens_List from "./Listoftokens";
import Loader from "./Loader";
import "../../css/swap.css";

export default function Swap({ DB_API, theme }) {
  const [data, setData] = useState({});
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [selectedToken1, setSelectedToken1] = useState("");
  const [selectedToken2, setSelectedToken2] = useState("");
  const [availableBalance1, setAvailableBalance1] = useState(0);
  const [availableBalance2, setAvailableBalance2] = useState(0);
  const [selectedTokenData1, setSelectedTokenData1] = useState("");
  const [selectedTokenData2, setSelectedTokenData2] = useState("");
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [maticPrice, setMaticPrice] = useState(0);
  const [wethPrice, setWethPrice] = useState(0);
  const [usdcPrice, setUSDCPrice] = useState(0);
  const [wbtcPrice, setWBTCPrice] = useState(0);
  const [solPrice, setSolPrice] = useState(0);
  const [polyPrice, setPolyPrice] = useState(0);
  const [usdtPrice, setUSDTPrice] = useState(0);
  const [daiPrice, setDaiPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchingEmail = localStorage.getItem("email");

  const isInputDisabled2 = !selectedToken2;

  const fetchingUserBalance = async () => {
    try {
      const { data } = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: fetchingEmail,
      });
      setData(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchingUserBalance();
  }, []);

  useEffect(() => {
    const fetchTokenPrice = async (tokenName, setPrice) => {
      try {
        const response = await axios.get(
          `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${tokenName}&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en`
        );

        // Assuming the response contains the price information
        const price = response.data[0]?.current_price || 0;
        setPrice(price);
        console.log(`${tokenName} Price:`, price);
      } catch (error) {
        console.error(`Error fetching ${tokenName} price:`, error);
      }
    };

    if (selectedToken2 === "WMATIC") {
      fetchTokenPrice("wmatic", setMaticPrice);
    } else if (selectedToken2 === "WETH") {
      fetchTokenPrice("weth", setWethPrice);
    } else if (selectedToken2 === "USDC") {
      fetchTokenPrice("usd-coin", setUSDCPrice);
    } else if (selectedToken2 === "WBTC") {
      fetchTokenPrice("wrapped-bitcoin", setWBTCPrice);
    } else if (selectedToken2 === "Solana") {
      fetchTokenPrice("solana", setSolPrice);
    } else if (selectedToken2 === "Polygon") {
      fetchTokenPrice("matic-network", setPolyPrice);
    } else if (selectedToken2 === "Tether") {
      fetchTokenPrice("tether", setUSDTPrice);
    } else if (selectedToken2 === "Dai") {
      fetchTokenPrice("dai", setDaiPrice);
    }
  }, [selectedToken2]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput1(value);
    if (selectedToken2 === "WMATIC") {
      setReceivedAmount(value / maticPrice);
      setInput2(value / maticPrice);
    } else if (selectedToken2 === "WETH") {
      setReceivedAmount(value / wethPrice);
      setInput2(value / wethPrice);
    } else if (selectedToken2 === "USDC") {
      setReceivedAmount(value / usdcPrice);
      setInput2(value / usdcPrice);
    } else if (selectedToken2 === "WBTC") {
      setReceivedAmount(value / wbtcPrice);
      setInput2(value / wbtcPrice);
    } else if (selectedToken2 === "Solana") {
      setReceivedAmount(value / solPrice);
      setInput2(value / solPrice);
    } else if (selectedToken2 === "Polygon") {
      setReceivedAmount(value / polyPrice);
      setInput2(value / polyPrice);
    } else if (selectedToken2 === "Tether") {
      setReceivedAmount(value / usdtPrice);
      setInput2(value / usdtPrice);
    } else if (selectedToken2 === "Dai") {
      setReceivedAmount(value / daiPrice);
      setInput2(value / daiPrice);
    }
  };

  const handleToggle = () => {
    // Swap balances
    const tempAvailableBalance = availableBalance1;
    setAvailableBalance1(availableBalance2);
    setAvailableBalance2(tempAvailableBalance);

    // Swap inputs
    const tempInput = input1;
    setInput1(input2);
    setInput2(tempInput);

    // Swap received amount
    const tempReceivedAmount = receivedAmount;
    setReceivedAmount(input2);
    setInput2(tempReceivedAmount);

    // Swap selected token values
    const tempSelectedToken = selectedToken1;
    setSelectedToken1(selectedToken2);
    setSelectedToken2(tempSelectedToken);

    // Swap selected token data values in the dropdown
    const tempSelectedTokenData = selectedTokenData1;
    setSelectedTokenData1(selectedTokenData2);
    setSelectedTokenData2(tempSelectedTokenData);
  };

  const handleTokenChangeBalance = async (selectedToken, inputNumber) => {
    const tradingTokenData = data?.findingUserData.trading_token;
    console.log("tradingTokenData:", tradingTokenData);
    // Find the selected token data
    const selectedTokenData = tradingTokenData.find(
      (token) => token.tokenname === selectedToken
    );

    // Update the balance and selected token data for the corresponding input
    if (selectedTokenData) {
      const element = selectedTokenData.tokenamount;

      if (inputNumber === 1) {
        setAvailableBalance1(element);
        setSelectedTokenData1(selectedToken);
        setInput1(""); // Reset input1 value if token is changed
        setSelectedToken1(selectedToken);
      } else {
        setAvailableBalance2(element);
        setSelectedTokenData2(selectedToken);
        setInput2(""); // Reset input2 value if token is changed
        setSelectedToken2(selectedToken);
      }
    } else {
      // If the selected token is not found in tradingTokenData, set the token without balance
      if (inputNumber === 1) {
        setAvailableBalance1(0);
        setSelectedTokenData1(selectedToken);
        setInput1(""); // Reset input1 value if token is changed
        setSelectedToken1(selectedToken);
      } else {
        setAvailableBalance2(0);
        setSelectedTokenData2(selectedToken);
        setInput2(""); // Reset input2 value if token is changed
        setSelectedToken2(selectedToken);
      }
    }
  };

  const SWAP_TOKENS = async () => {
    setLoading(true);
    try {
      const { data } = await axios.put(`${DB_API}/api/swaptokens`, {
        email: fetchingEmail,
        fromToken: selectedToken1,
        toToken: selectedToken2,
        enterAmount: Number(input1) || Number(availableBalance1),
        receivedAmount: input2,
      });
      toast.success(data.message);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const SWAP_HISTORY = async () => {
    try {
      await axios.put(`${DB_API}/api/transcationhistoryswap`, {
        email: fetchingEmail,
        fromToken: selectedToken1,
        toToken: selectedToken2,
        enterAmount: Number(input1) || Number(availableBalance1),
        receivedAmount: input2,
      });
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handleSwap = () => {
    SWAP_TOKENS();
    SWAP_HISTORY();
  };

  const handlePercentageButtonClick = (percentage) => {
    if (!selectedTokenData1) {
      toast.error("Please select a token for input first.");
      return;
    }
    // Calculate the amount based on the selected percentage
    const percentageAmount = (availableBalance1 * percentage) / 100;
    // Calculate the corresponding amount for input2 based on the token price
    let calculatedAmount;
    if (selectedToken2 === "WMATIC") {
      calculatedAmount = percentageAmount / maticPrice;
    } else if (selectedToken2 === "WETH") {
      calculatedAmount = percentageAmount / wethPrice;
    }
    // Set the calculated amount in both input fields
    setInput1(percentageAmount);
    setInput2(calculatedAmount);
  };

  return (
    <>
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Sidenavbar theme={theme} />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9}>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex">
                <div className="text-center">
                  <img
                    src={avatar}
                    className="w-25 rounded-circle"
                    alt="avatar"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="swap-child">
                <div>
                  <div className="cursor-pointer">
                    <AiOutlineCloseCircle className="mr-0.5" />
                    Close
                  </div>
                  <div className="text-center mt-3">
                    <h4 className="font-weight-bold">Swap Assets</h4>
                    <p>Select the asset you want to swap</p>
                  </div>
                </div>
                {loading && <Loader />}
                <div className="token-tab position-relative">
                  {/* Input 1 */}
                  <div className="m-t-1">
                    <input
                      className={`eth-in input_color ${
                        input1 > availableBalance1 ? "error" : ""
                      }`}
                      placeholder="Enter amount to swap"
                      type="number"
                      value={input1}
                      onChange={handleInputChange}
                      disabled={!selectedToken1}
                    />
                    <div className="dropdown_swap">
                      <select
                        className="eth-in"
                        value={selectedToken1}
                        onChange={(e) => {
                          const selectedToken = e.target.value;
                          handleTokenChangeBalance(selectedToken, 1);
                        }}
                      >
                        <option value="">Select a token</option>
                        {Tokens_List.map((token, index) => (
                          <option key={index} value={token.name}>
                            {token.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p>
                      Balance: {availableBalance1 ? availableBalance1 : 0}${" "}
                      {selectedTokenData1}
                    </p>
                    {input1 > availableBalance1 && (
                      <p className="error">
                        Insufficient balance: {input1}$ {selectedTokenData1}
                      </p>
                    )}
                  </div>
                  {/* Input 2 */}
                  <div className="m-t-1">
                    <input
                      className="eth-in"
                      placeholder="Received amount"
                      type="number"
                      value={input2}
                      disabled={isInputDisabled2}
                    />
                    <div className="dropdown_swap_2">
                      <select
                        className="eth-in"
                        value={selectedToken2}
                        onChange={(e) => {
                          const selectedToken = e.target.value;
                          handleTokenChangeBalance(selectedToken, 2);
                        }}
                      >
                        <option value="">Select a token</option>
                        {Tokens_List.map((token, index) => (
                          <option key={index} value={token.name}>
                            {token.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p>
                      Balance : {availableBalance2 ? availableBalance2 : 0}${" "}
                      {selectedTokenData2}
                    </p>
                  </div>

                  <CgArrowsExchange
                    className="exchange-swap f-s-2 swap_edit"
                    onClick={handleToggle}
                  />
                </div>

                <div className="text-end mt-1">
                  <Button
                    variant="outlined"
                    className="mr-1 swap-bu"
                    onClick={() => handlePercentageButtonClick(25)}
                  >
                    25%
                  </Button>
                  <Button
                    variant="outlined"
                    className="mr-1 swap-bu"
                    onClick={() => handlePercentageButtonClick(50)}
                  >
                    50%
                  </Button>
                  <Button
                    variant="outlined"
                    className="mr-1 swap-bu"
                    onClick={() => handlePercentageButtonClick(75)}
                  >
                    75%
                  </Button>
                  <Button
                    variant="outlined"
                    className="mr-1 swap-bu"
                    onClick={() => handlePercentageButtonClick(100)}
                  >
                    Max
                  </Button>
                </div>
                <div className="mt-2">
                  <Button
                    variant="contained"
                    color="primary"
                    className="w-100 p-1"
                    onClick={handleSwap}
                  >
                    Swap
                  </Button>
                </div>
                <div className="mt-2 text-center">
                  <p className="font-size-0_8">
                    Only trading balance can be swapped
                  </p>
                  <p className="font-size-0_8">
                    Click Transfer to move Assets from funding to trading
                    balance.
                  </p>
                  {selectedToken2 === "WMATIC" && (
                    <p>Current WMATIC Price: {maticPrice} USD</p>
                  )}
                  {selectedToken2 === "WETH" && (
                    <p>Current WETH Price: {wethPrice} USD</p>
                  )}
                  {selectedToken2 === "WBTC" && (
                    <p>Current WBTC Price: {wbtcPrice} USD</p>
                  )}
                  {selectedToken2 === "Polygon" && (
                    <p>Current Polygon Price: {polyPrice} USD</p>
                  )}
                  {selectedToken2 === "Solana" && (
                    <p>Current Solana Price: {solPrice} USD</p>
                  )}
                  {selectedToken2 === "Tether" && (
                    <p>Current Tether Price: {usdtPrice} USD</p>
                  )}
                  {selectedToken2 === "USDC" && (
                    <p>Current USDC Price: {usdcPrice} USD</p>
                  )}
                  {selectedToken2 === "Dai" && (
                    <p>Current Dai Price: {daiPrice} USD</p>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
