import { Grid } from "@mui/material";
import React from "react";
import Sidenavbar from "./Sidenavbar";
import comingsoon from "../../Assets/Image/27282.jpg";
import avatar from "../../Assets/Image/zendwallet-avatar.png";
import Nav from "react-bootstrap/Nav";
import Assetstabs from "./Assetstabs";

export default function Assets({ DB_API, theme }) {
  return (
    <>
      <div>
        <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar theme={theme} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
            {/*  <div>
              <img src={comingsoon} className="h-in-v"/>
            </div> */}
            <div>
              <div className="d-f j-c-s-b m-b-2">
                <div className="pa-0_5">
                  <h1>Assets</h1>
                  <p>List of assets available on Pastewallet</p>
                </div>
                <div className="d-f">
                  <div className="t-a-c">
                    <img src={avatar} className="w-25 b-r-25 " />
                  </div>
                </div>
              </div>
            </div>
            <Assetstabs DB_API={DB_API} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
