import React, { useLayoutEffect, useState } from "react";
import { BsMoon } from "react-icons/bs";
import registerimg from "../../Assets/Image/auth-bg.png";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";
import logo from "../../Assets/logo/pastewallet2.png";
import darklogo from "../../Assets/logo/pastewallet1.png";

export default function Sendotp({ DB_API, theme }) {
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    const init = async () => {
      const adminInfo = localStorage.getItem("email");
      const adminData = JSON.parse(adminInfo);
      if (adminData) {
        window.location.replace("/dashboard");
      }
    };
    init();
  }, []);

  const Send_OTP = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${DB_API}/api/sendotp`, {
        email: email,
      });
      toast.success(data.message);
      localStorage.setItem("email", data.email);
      if (data.message) {
        setTimeout(() => {
          navigate("/register");
        }, 3000);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="container-new pa-1">
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="create-bg">
              <div className="d-f j-c-s-b m-t-1">
                <div className="image-dog1">
                  {theme != "dark-theme" ? (
                    <a href="/dashboard">
                      <img src={logo} alt="" className="logoimg" />
                    </a>
                  ) : (
                    <a href="/dashboard">
                      <img src={darklogo} alt="" className="logoimg" />
                    </a>
                  )}
                </div>
              </div>
              <div className="pa-4">
                <h3 className="f-s-2 f-w-800">Create Account</h3>
                <p className="sub-ti">Kindly provide the information we need</p>
                <div className="form">
                  <div className="m-t-2">
                    <p className="m-b-0_5">Email</p>
                    <input
                      type="text"
                      placeholder="Enter your email"
                      className="input-email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="t-a-c">
                    <button
                      className="w-70 m-t-1 m-b-1 f-w-600 create-btn"
                      onClick={Send_OTP}
                    >
                      Submit
                    </button>
                  </div>
                  <div>
                    <p className="f-s-1_3 t-a-c">
                      Already have an account?{" "}
                      <NavLink to="/">
                        <span className="log c-p">Log In</span>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 login-hide">
            <div className="registerimg-div p-t-5">
              <img src={registerimg} className="w-100" />
            </div>

            <p></p>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}
