import React, { useEffect, useState } from "react";
import Sidenavbar from "../pages/Sidenavbar";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../pages/Loader";

export default function Userdetail({ theme, DB_API }) {
  const [userData, setUserData] = useState([]);
  const [isSuspend, setIsSuspend] = useState(false);
  const [loader, setLoader] = useState(false);

  const { email } = useParams();

  const Fetching_Global_User_Data = async () => {
    try {
      const response = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: email,
      });

      const user = response.data.findingUserData;

      const tokensData = user.deposit_token.map((token) => ({
        tokenName: token.tokenname,
        tokenAmount: token.tokenamount,
      }));

      setUserData(tokensData);
      setIsSuspend(user.isSuspended);
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      Fetching_Global_User_Data();
    }, 1500);
    return () => clearInterval(intervalId);
  }, []);

  const Suspend_User = async () => {
    try {
      setLoader(true);
      const { data } = await axios.post(`${DB_API}/api/admin/suspend`, {
        email: email,
      });
      toast.success(data.message);
    } catch (error) {
      toast.error(error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const UnSuspend_User = async () => {
    try {
      setLoader(true);
      const { data } = await axios.post(`${DB_API}/api/admin/unsuspend`, {
        email: email,
      });
      toast.success(data.message);
    } catch (error) {
      toast.error(error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      <div>
        {loader && <Loader />}
        <div>
          <div className="status_border">
            {isSuspend ? (
              <button className="status_staus_access" onClick={UnSuspend_User}>
                UnSuspend
              </button>
            ) : (
              <button className="status_staus_access" onClick={Suspend_User}>
                {" "}
                Suspend
              </button>
            )}
          </div>
          <Link to="/adminpanel">
            <p
              style={{
                textAlign: "center",
                textDecoration: "underline",
                margin: "10px 0px",
              }}
            >
              Go Back
            </p>
          </Link>
          <div className="t-a-c mt-5 mb-5">
            <h3>User Deposit History</h3>
          </div>

          <div className="container">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SNO.</TableCell>
                    <TableCell align="center">Token Name</TableCell>
                    <TableCell align="center">Deposit Amount</TableCell>
                  </TableRow>
                </TableHead>
                {userData.length > 0 ? (
                  <TableBody>
                    {userData.map((tokenData, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}.</TableCell>
                        <TableCell align="center">
                          {tokenData.tokenName}
                        </TableCell>
                        <TableCell align="center">
                          {tokenData.tokenAmount}$
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody style={{ height: "20vh", position: "relative" }}>
                    <p
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "37%",
                      }}
                    >
                      No Deposit Data To Show
                    </p>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>

          <div className="t-a-c mt-5 mb-5">
            <h3>User Swap History</h3>
          </div>

          <div className="container">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SNO.</TableCell>
                    <TableCell align="center">Swap From</TableCell>
                    <TableCell align="center">Swap Amount</TableCell>
                    <TableCell align="center">Swap To</TableCell>
                    <TableCell align="center">Received Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ height: "20vh", position: "relative" }}>
                  <p
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "37%",
                    }}
                  >
                    No Swap Data To Show
                  </p>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="t-a-c mt-5 mb-5">
            <h3>User Withdraw History</h3>
          </div>

          <div className="container">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SNO.</TableCell>
                    <TableCell align="center">Token Name</TableCell>
                    <TableCell align="center">Withdraw Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ height: "20vh", position: "relative" }}>
                  <p
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "37%",
                    }}
                  >
                    No Withdraw Data To Show
                  </p>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Grid container>
        <Grid item lg={3} xl={2}>
          <Sidenavbar theme={theme} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>

        </Grid>
      </Grid> */}
    </div>
  );
}
