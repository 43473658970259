import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidenavbar from "./Sidenavbar";
import avatar from "../../Assets/Image/zendwallet-avatar-removebg-preview.png";
import { IoIosArrowRoundBack } from "react-icons/io";
import Tokens_List from "./Listoftokens";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";
import Modal from "react-bootstrap/Modal";
import { Withdraw_Tokens } from "../Web3/walletconnect";

export default function Withdrawassets({ DB_API, theme, account }) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [currency, setCurrency] = useState("USD");
  const [selectedToken, setSelectedToken] = useState("");
  const [availableBalance, setAvailableBalance] = useState(0);
  const [maxBalance, setMaxBalance] = useState();
  const [usdPrice, setUSDPrice] = useState();
  const [enterAmount, setEnterAmount] = useState();
  const [transactionFee, setTranscationFee] = useState(0.001);
  const [usdAmount, setUsdAmount] = useState(0);
  const [insufficientBalanceError, setInsufficientBalanceError] =
    useState(false);
  const [isTxPin, setIsTxPin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPin, setCurrentPin] = useState(""); // Add state for current pin
  const [tokenAddress, setTokenAddress] = useState("");

  const FetchingEmail = localStorage.getItem("email");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeInput = (e) => {
    const value = e.target.value;
    setEnterAmount(value);
    setMaxBalance(value);

    if (value.trim() === "") {
      setUsdAmount("0");
      return;
    }

    if (parseFloat(value) > parseFloat(availableBalance)) {
      setInsufficientBalanceError(true);
      return;
    } else {
      setInsufficientBalanceError(false);
    }

    if (selectedToken && usdPrice) {
      const receivedAmountUSD = parseFloat(value) * parseFloat(usdPrice);
      setUsdAmount(receivedAmountUSD.toFixed(2));
    } else {
      setUsdAmount("0");
    }
  };

  const fetchingUserBalance = async () => {
    try {
      const { data } = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: FetchingEmail,
      });
      setData(data);
      setIsTxPin(data.findingUserData.isTxPin);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleTokenChangeBalance = async (selectedToken) => {
    const swapTokenData = data?.findingUserData.swap_token;

    const selectedTokenData = swapTokenData.find(
      (token) => token.toToken === selectedToken
    );

    if (selectedTokenData) {
      const receivedAmount = selectedTokenData.receivedAmount;
      setAvailableBalance(receivedAmount);
    } else {
      setAvailableBalance(0);
    }
  };

  const Fetching_USD_Price = async () => {
    try {
      const { data } = await axios.get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=usd&per_page=100&page=1&sparkline=false&locale=en"
      );
      setUSDPrice(data[0].current_price);
    } catch (error) {
      toast.error("We are fetching Latest Price please wait for a while");
    }
  };

  const handleMaxBalance = async () => {
    try {
      setMaxBalance(availableBalance);
      const receivedAmountUSD =
        parseFloat(availableBalance) * parseFloat(usdPrice);
      setUsdAmount(receivedAmountUSD.toFixed(2));
      setEnterAmount(availableBalance.toString());
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    fetchingUserBalance();
    Fetching_USD_Price();
  }, []);

  const Withdraw_Balance = async (_tokenAddress, _amount) => {
    try {
      setLoading(true);
      const token = Tokens_List.find((token) => token.name === selectedToken);
      console.log("token:", token);
      if (!token) {
        throw new Error("Invalid token selected");
      }

      // Convert finalReceivedAmount to the appropriate integer value
      const convertedAmount = Number(usdAmount);

      const withdrawTransaction = await Withdraw_Tokens(
        token.address,
        convertedAmount
      );

      console.log("withdrawTransaction:", withdrawTransaction);
      console.log("convertedAmount :", convertedAmount);

      if (!withdrawTransaction) {
        console.error("Error fetching token name.");
        toast.error("Error fetching token name.");
        setLoading(false);
        return; // Exit early if withdrawal transaction fails
      }

      // try {
      //   const { data } = await axios.put(`${DB_API}/api/withdrawtoken`, {
      //     email: FetchingEmail,
      //     tokenName: selectedToken,
      //     enterAmount: usdAmount,
      //     transactionFee: transactionFee,
      //     walletAddress: account,
      //     receivedAmount: Number(finalReceivedAmount),
      //     transactionpin: currentPin,
      //   });
      //   toast.success(data.message);
      // } catch (error) {
      //   // Check if the error is due to user rejection of MetaMask transaction
      //   if (
      //     error.code === -32603 &&
      //     error.message.includes("User denied transaction")
      //   ) {
      //     // Update the withdrawal history with "failed" status
      //     await Withdraw_Balance_History("failed");
      //     toast.error("Transaction rejected by user.");
      //   } else {
      //     toast.error(
      //       error.response ? error.response.data.message : error.message
      //     );
      //   }
      // }
    } catch (error) {
      toast.error(error.response ? error.response.data.message : error.message);
      console.log("error:", error);
    } finally {
      setLoading(false);
    }
  };

  const finalReceivedAmount = (Number(usdAmount) - transactionFee).toFixed(5);

  const Withdraw_Balance_History = async (status) => {
    try {
      const { data } = await axios.put(
        `${DB_API}/api/transcationhistorywithdraw`,
        {
          email: FetchingEmail,
          tokenName: selectedToken,
          enterAmount: Number(enterAmount) || Number(maxBalance),
          walletAddress: account,
          receivedAmount: Number(finalReceivedAmount),
          status: status, // Update status here
        }
      );
    } catch (error) {
      console.log("error:", error);
    }
  };

  // const handleWithdrawBalance = () => {
  //   if (isTxPin === false) {
  //     toast.warning(
  //       "Please create a Transaction Pin before withdrawing amount"
  //     );
  //     return;
  //   } else if (!currentPin) {
  //     toast.error("Please enter your current transaction pin");
  //     return;
  //   } else {
  //     // If transaction pin is entered, proceed with withdrawal
  //     Withdraw_Balance(); // Call Withdraw_Balance function to withdraw balance
  //     Withdraw_Balance_History(); // Record transaction history
  //   }
  // };

  return (
    <>
      <div>
        <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar theme={theme} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
            <div>
              <div>
                <div className="d-f j-c-e m-b-2">
                  <div className="d-f">
                    <div className="t-a-c">
                      <img src={avatar} className="w-25 b-r-25 " alt="avatar" />
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="deposite-child">
                    <div>
                      <div className="c-p">
                        <IoIosArrowRoundBack className="m-r-0_5 b-c-t" />
                        Back
                      </div>
                      <div className="t-a-c m-t-3">
                        <h4 className="f-w-600">Withdraw Assets</h4>
                        <p>Kindly provide the necessary details</p>
                      </div>
                      {loading && <Loader />}
                      <div className="m-t-1">
                        <p className="m-t-0_5">
                          Balance:
                          {availableBalance !== null && (
                            <p>Available Balance: ${availableBalance}</p>
                          )}
                        </p>

                        {enterAmount > availableBalance && (
                          <p className="error">
                            Insufficient balance: {enterAmount}$ {selectedToken}
                          </p>
                        )}
                      </div>

                      <div className="">
                        <div className="">
                          <select
                            className="eth-in"
                            value={selectedToken}
                            onChange={(e) => {
                              const selectedToken = e.target.value;
                              setSelectedToken(selectedToken);
                              handleTokenChangeBalance(selectedToken);
                            }}
                          >
                            <option value="">Select a token</option>
                            {Tokens_List.map((token, index) => (
                              <option key={index} value={token.name}>
                                {token.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" c-p m-t-1">
                          <div className="d-f j-c-s-b enter">
                            <input
                              placeholder="Enter Amount"
                              className={`eth-in input_color ${
                                enterAmount > availableBalance ? "error" : ""
                              }`}
                              value={enterAmount || maxBalance}
                              onChange={handleChangeInput}
                              disabled={!selectedToken}
                            />
                            <div className="enter-ee">
                              <button
                                className="button-blue mt-2"
                                onClick={handleMaxBalance}
                                disabled={!selectedToken}
                              >
                                Max
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className=" c-p m-t-1">
                          <div className="m-t-1 ps-r">
                            <input
                              className="eth-in"
                              placeholder="Amount In USD"
                              value={usdAmount ? usdAmount : 0}
                              disabled={
                                !selectedToken || insufficientBalanceError
                              }
                            />
                            <label className="usd-amount">USD</label>
                          </div>
                        </div>

                        <div className="m-t-1">
                          <input
                            placeholder="currency"
                            className="eth-in"
                            value={currency}
                          />
                        </div>

                        <div className=" c-p m-t-1">
                          <div className="m-t-1 ps-r">
                            {account ? (
                              <input
                                className="eth-in"
                                placeholder="walletaaddress"
                              />
                            ) : (
                              <>
                                <input
                                  className="eth-in"
                                  placeholder="Wallet Address"
                                  value="Please connect your wallet address."
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="m-t-1">
                          <label className="eth-la">Transaction Fee</label>
                          <input
                            placeholder="Fee: 0"
                            className="eth-in"
                            value={transactionFee}
                          />
                        </div>
                        <div className="eth-in m-t-2">
                          <div className="d-f j-c-s-b border-bo">
                            <p>Fees</p>
                            <p>{transactionFee} USD</p>
                          </div>
                          <div className="d-f j-c-s-b m-t-0_5">
                            <p>Recipients will receive</p>
                            <p>
                              {finalReceivedAmount < 0
                                ? "0.00"
                                : finalReceivedAmount}
                              USD
                            </p>
                          </div>
                        </div>
                        <button
                          className="button-blue w-100 m-t-1"
                          onClick={handleShow}
                          disabled={!selectedToken || insufficientBalanceError}
                        >
                          Withdraw Assets
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              backdrop="static"
              keyboard={false}
              show={show}
              onHide={handleClose}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="t-a-l">
                <div className="m-t-2">
                  <p className="m-b-0_5">Enter Current Pin</p>
                  <input
                    type="text"
                    placeholder="Enter current transaction pin"
                    className="input-email-log"
                    value={currentPin}
                    maxLength={5}
                    onChange={(e) => setCurrentPin(e.target.value)}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer className="t-a-c j-c-c">
                <div className="t-a-c">
                  <button
                    className="button-blue w-100"
                    onClick={Withdraw_Balance}
                  >
                    Withdraw Assets
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </Grid>
        </Grid>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
