import { Grid } from "@mui/material";
import React from "react";
import Sidenavbar from "./Sidenavbar";
import avatar from "../../Assets/Image/zendwallet-avatar.png";
import TansactionTabs from "../../component/pages/TansactionTabs";
import { IoIosArrowForward } from "react-icons/io";

export default function Transactiondetail({ theme, DB_API }) {
  return (
    <>
      <div>
        {" "}
        <Grid container>
          <Grid item lg={3} xl={2}>
            <Sidenavbar theme={theme} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
            <div>
              <div className="d-f j-c-s-b m-b-2">
                <div>
                  <h5>
                    Transaction <IoIosArrowForward /> Token Transaction
                  </h5>
                </div>
                <div>
                  {" "}
                  <div className="t-a-c">
                    <img src={avatar} className="w-25 b-r-25 " />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <TansactionTabs DB_API={DB_API} />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
