import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AiOutlineMenu } from "react-icons/ai";
import { FaMoon, FaSun } from "react-icons/fa";
import logo from "../../Assets/Image/nfe-logo.png";
import { NavLink } from "react-router-dom";
import "animate.css";

function Navbar({ theme, toggleThemedark, toggleThemelight }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="  navbar12  d-f a-i-c j-c-s-b m-r-2">
        <div className="">
          {/*   <ul className="l-s-t-n d-f a-i-c ">
            <li className="m-r-2">
              <img
                src={logo}
                alt=""
                className="logo animate__animated animate__rubberBand"
              />
            </li>

            <li className="m-r-2 c-p   m-q-b-d-n">dummy</li>
            <li className="m-r-2 c-p   m-q-b-d-n">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="b-c-t b-n c-i"
                >
                  Dropdown Button
                </Dropdown.Toggle>

                <Dropdown.Menu className="pa-1">
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul> */}
        </div>
        <div className=" c-p ">
          <ul className="l-s-t-n d-f a-i-c ">
            {/*   <li className="m-l-2 c-p   m-q-b-d-n">dummy</li> */}

            {/*  <li className="m-l-2 c-p   m-q-b-d-n">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="b-c-t b-n c-i"
                >
                  Dropdown Button
                </Dropdown.Toggle>

                <Dropdown.Menu className="pa-1">
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            {/* <li className="m-l-2 c-pq ">
            <span className="" onClick={() => toggleTheme()}>{theme === "dark-theme" ? "Light mode" : "Dark mode"}</span>
            </li> */}
            {/*   <li className="m-l-2 c-pq ">
              {" "}
              <Button
                variant="primary"
                onClick={handleShow2}
                className=" b-c-t pa-0_5 c-i b-c-i"
              >
                Connect Wallet
              </Button>
            </li> ` */}
            <li className="m-l-2 c-p   m-q-a-d-n ">
              {" "}
              <Button onClick={handleShow} className=" b-c-t c-i b-n pa-0">
                <AiOutlineMenu />
              </Button>
            </li>
          </ul>
          <ul className="l-s-t-n">
            <li>
              {theme != "dark-theme" ? (
                <span
                  onClick={() => toggleThemedark()}
                  className=" f-w-600 f-s-1_25 f-f-int c-p"
                >
                  <FaMoon className="f-s-1_5 b-c-t" />
                </span>
              ) : (
                <span
                  onClick={() => toggleThemelight()}
                  className=" f-w-600 f-s-1_25 f-f-int c-p"
                >
                  <FaSun className="f-s-1_5 b-c-t" />
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>

      {/* ---------------mobile-navbar---------------------------------- */}

      <Offcanvas show={show} onHide={handleClose} className="pa-1">
        <Offcanvas.Header>
          <Offcanvas.Title>
            {" "}
            <div className="image-dog1">
              <a href="/" target="_blank">
                <img src={logo} alt="" className="" />
              </a>
            </div>
          </Offcanvas.Title>
          <ImCross onClick={handleClose} color="black" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="l-s-t-n">
            <NavLink to="/dashboard">
              {" "}
              <li
                className="m-r-1 m-t-1 c-p btn-sm f-w-800 f-s-1_5 "
                onClick={() => handleClose()}
              >
                Dashboard
              </li>
            </NavLink>
            <NavLink to="/assets">
              <li
                className="m-r-1 m-t-1 c-p  btn-sm f-w-800 f-s-1_5"
                onClick={() => handleClose()}
              >
                Assets
              </li>
            </NavLink>
            <NavLink to="/transaction">
              {" "}
              <li
                className="m-r-1 m-t-1 c-p  btn-sm f-w-800 f-s-1_5"
                onClick={() => handleClose()}
              >
                Transaction
              </li>
            </NavLink>
            <NavLink to="/depositetoken">
              {" "}
              <li
                className="m-r-1 m-t-1 c-p  btn-sm f-w-800 f-s-1_5"
                onClick={() => handleClose()}
              >
                Deposite
              </li>
            </NavLink>
            <NavLink to="/swap">
              <li
                className="m-r-1 m-t-1 c-p  btn-sm f-w-800 f-s-1_5"
                onClick={() => handleClose()}
              >
                Swap
              </li>
            </NavLink>
            <NavLink to="/withdraw">
              <li
                className="m-r-1 m-t-1 c-p  btn-sm f-w-800 f-s-1_5"
                onClick={() => handleClose()}
              >
                Withdraw
              </li>
            </NavLink>
            <NavLink to="/transfer">
              <li
                className="m-r-1 m-t-1 c-p  btn-sm f-w-800 f-s-1_5"
                onClick={() => handleClose()}
              >
                Transfer
              </li>
            </NavLink>
            <NavLink to="/card">
              {" "}
              <li
                className="m-r-1 m-t-1 c-p  btn-sm f-w-800 f-s-1_5"
                onClick={() => handleClose()}
              >
                Paste Card
              </li>
            </NavLink>
            <NavLink to="/cardcoin">
              <li
                className="m-r-1 m-t-1 c-p btn-sm f-w-800 f-s-1_5"
                onClick={() => handleClose()}
              >
                Paste Coin
              </li>
            </NavLink>
          </ul>
          <ul className="l-s-t-n">
            {" "}
            <NavLink to="/accountsetting">
              {" "}
              <li
                className="m-r-1 m-t-1 c-p  btn-sm f-w-800 f-s-1_5"
                onClick={() => handleClose()}
              >
                Account Setting
              </li>
            </NavLink>
            <li
              className="m-r-1 m-t-1 c-p btn-sm f-w-800 f-s-1_5"
              onClick={() => handleClose()}
            >
              Log Out
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Navbar;
