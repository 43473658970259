import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
  Tabs,
  Tab,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SwapTxTab({ DB_API }) {
  const [value, setValue] = useState(0);
  const [rows, setRows] = useState([]);
  const [successfullTx, setSuccessfulTransactions] = useState([]);
  const [failedTx, setFailedTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const FetchingEmail = localStorage.getItem("email");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Fetching_User_Transactions = async () => {
    try {
      const { data } = await axios.post(`${DB_API}/api/fetchProfile`, {
        email: FetchingEmail, // Assuming FetchingEmail is defined elsewhere
      });

      const rows = [];
      const successfulTransactions = [];
      const failedTransactions = [];

      if (
        data &&
        data.findingUserData &&
        data.findingUserData.swap_transcation_history
      ) {
        data.findingUserData.swap_transcation_history.forEach((transaction) => {
          const txId = transaction.transactionId;
          const tokenname = transaction.fromToken;
          const enterAmount = transaction.enterAmount;
          const toToken = transaction.toToken;
          const receivedAmount = transaction.receivedAmount;
          const Status = transaction.status;

          rows.push({
            txId,
            tokenname,
            enterAmount,
            toToken,
            receivedAmount,
            Status,
          });

          if (Status && Status.toLowerCase() === "success") {
            successfulTransactions.push({
              txId,
              tokenname,
              enterAmount,
              toToken,
              receivedAmount,
              Status,
            });
          } else {
            failedTransactions.push({
              txId,
              tokenname,
              enterAmount,
              toToken,
              receivedAmount,
              Status,
            });
          }
        });
      }

      setRows(rows);
      setSuccessfulTransactions(successfulTransactions);
      setFailedTransactions(failedTransactions);
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    Fetching_User_Transactions();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="All" className="fund" {...a11yProps(0)} />
        <Tab label="Successful" className="fund" {...a11yProps(1)} />
        <Tab label="Failed" className="fund" {...a11yProps(2)} />
      </Tabs>

      <CustomTabPanel value={value} index={0}>
        {rows.length ? (
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table aria-label="transactions table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    SNO
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Transaction ID
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Swap From
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Enter Amount
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Swap To
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Received Amount
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}.
                      </TableCell>
                      <TableCell align="center">{row.txId}</TableCell>
                      <TableCell align="center">{row.tokenname}</TableCell>
                      <TableCell align="center">{row.enterAmount}</TableCell>
                      <TableCell align="center">{row.toToken}</TableCell>
                      <TableCell align="center">{row.receivedAmount}</TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            color:
                              (row.Status &&
                                row.Status.toLowerCase() === "success") ||
                              (row.status &&
                                row.status.toLowerCase() === "success") ||
                              row.successful === true
                                ? "green"
                                : "red",
                          }}
                        >
                          {row.Status ||
                            row.status ||
                            (row.successful === true ? "success" : "failed")}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="row">
            <div>
              <div className="t-a-c m-t-7">
                <h1>No Transactions Yet</h1>
                <p>
                  This Place is empty because you haven't done any transaction
                </p>
              </div>
            </div>
          </div>
        )}

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {successfullTx.length ? (
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table aria-label="transactions table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    SNO
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Transaction ID
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Swap From
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Enter Amount
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Swap To
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Received Amount
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {successfullTx
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}.
                      </TableCell>
                      <TableCell align="center">{row.txId}</TableCell>
                      <TableCell align="center">{row.tokenname}</TableCell>
                      <TableCell align="center">{row.enterAmount}</TableCell>
                      <TableCell align="center">{row.toToken}</TableCell>
                      <TableCell align="center">{row.receivedAmount}</TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            color:
                              (row.Status &&
                                row.Status.toLowerCase() === "success") ||
                              (row.status &&
                                row.status.toLowerCase() === "success") ||
                              row.successful === true
                                ? "green"
                                : "red",
                          }}
                        >
                          {row.Status ||
                            row.status ||
                            (row.successful === true ? "success" : "failed")}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="row">
            <div>
              <div className="t-a-c m-t-7">
                <h1>No Transactions Yet</h1>
                <p>
                  This Place is empty because you haven't done any transaction
                </p>
              </div>
            </div>
          </div>
        )}

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {failedTx.length ? (
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table aria-label="transactions table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    SNO
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Transaction ID
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Swap From
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Enter Amount
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Swap To
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Received Amount
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {failedTx
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}.
                      </TableCell>
                      <TableCell align="center">{row.txId}</TableCell>
                      <TableCell align="center">{row.tokenname}</TableCell>
                      <TableCell align="center">{row.enterAmount}</TableCell>
                      <TableCell align="center">{row.toToken}</TableCell>
                      <TableCell align="center">{row.receivedAmount}</TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            color:
                              (row.Status &&
                                row.Status.toLowerCase() === "success") ||
                              (row.status &&
                                row.status.toLowerCase() === "success") ||
                              row.successful === true
                                ? "green"
                                : "red",
                          }}
                        >
                          {row.Status ||
                            row.status ||
                            (row.successful === true ? "success" : "failed")}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="row">
            <div>
              <div className="t-a-c m-t-7">
                <h1>No Transactions Yet</h1>
                <p>
                  This Place is empty because you haven't done any transaction
                </p>
              </div>
            </div>
          </div>
        )}

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CustomTabPanel>
    </Box>
  );
}

export default SwapTxTab;
